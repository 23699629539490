// Copyright (C) 2020 TANNER AG

import { C42Metadata, C42Touchpoint } from "./components/types";

/**
 * Find touchpoint metadata value in C42Touchpoint array.
 *
 * @param {C42Touchpoint[]} metadata values
 * @param {string} id metadata id
 * @param {string} defaultValue value to set if metadata not available
 * @returns {string} metadata value
 */
export function findTouchpointValue(metadata: C42Touchpoint[], id: string, defaultValue?: string): string {
	const data = metadata.find(item => item.id === id);

	if (!data) {
		if (defaultValue !== undefined) {
			return defaultValue;
		}

		return "";
	}

	return data.value;
}

/**
 * Find metadata values in C42Metadata array.
 *
 * @param {C42Metadata[]} metadata values
 * @param {string} id metadata id
 * @param {string} defaultValue value to set if metadata not available
 * @returns {string} metadata value
 */
export function findMetadataValues(metadata: C42Metadata[], id: string, defaultValue?: string[]): string[] {
	const data = metadata.find(item => item.id === id);

	if (!data || !data.values.length) {
		if (defaultValue !== undefined) {
			return defaultValue;
		}

		return [""];
	}

	return data.values;
}

/**
 * Decode html entities from string.
 *
 * @param {string} str string to decode
 * @returns {string} decoded string
 */
export const decodeHtmlEntity = (str: string): string =>
	String(str)
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;');

/**
 * Extract enum from string.
 * Example: 1.12.39 Heading => ["1.12.39", "Heading"]
 *
 * @param {string} str sting to extract enum
 * @returns {[string, string]} extracted enum and heading
 */
export const extractEnum = (str: string): [string, string] => {
	const regex = /^(([0-9]+.?)+)/gm;
	const matches = str.match(regex);
	const enumeration = (matches && matches[0]) || "";

	return [enumeration, str.substring(enumeration.length).trim()];
};
