// Copyright (C) 2019 TANNER AG

import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Link } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton"
import { ProductInfo } from "../../types";
import { MEDIA_SERVER_URL } from "../../../const";

const useStyles = makeStyles(() =>
	createStyles({
		card: {
			height: "100%",
			borderRadius: 2
		},
		actionArea: {
			height: "100%"
		},
		media: {
			height: 180
		}
	})
);

interface Props {
	id: string;
	product: ProductInfo;
}

const HomeItem: React.FC<Props> = ({ id, product }) => {
	const classes = useStyles();

	return (
		<Grid item xs={6} sm={3}>
			<Card className={classes.card}>
				<CardActionArea component={Link} to={`/products/${id}`} className={classes.actionArea}>
					{id ? (
						<CardMedia
							className={classes.media}
							image={`${MEDIA_SERVER_URL}/${id}_thumbnail.png`}
						/>
					) : (
						<Skeleton variant="rect" height={180}/>
					)}
					<CardContent>
						{product ? (
							<>
								<Typography
									align="center"
									variant="subtitle2"
									color="primary"
								>
									{product.name}
								</Typography>
								<Typography
									align="center"
									variant="body2"
									color="textSecondary"
								>
									{product.number}
								</Typography>
							</>
						) : (
							<>
								<Skeleton variant="text"/>
								<Skeleton variant="text"/>
							</>
						)}
					</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	);
};

export default HomeItem;
