// Copyright (C) 2019 TANNER AG

import React, { useEffect } from "react";
import DocumentDrawerHeader from "./documentdrawerheader";
import useShift from "@c42-ui/core/useshift";
import ActionTabs from "@c42-ui/core/actiontabs";
import useMetaInfoTabs from "../../usemetainfotabs";
import { useLocation, useHistory } from "react-router-dom";
import SideDrawer from "../../sidedrawer";
import useBreakpoint from "../../usebreakpoint";

export enum ActionTabIndex {
	Search
}

interface Props {
	activeIndex: ActionTabIndex;
	setActiveIndex(index: ActionTabIndex): void;
	documentId: string;
}

const DocumentDrawer: React.FC<Props> = ({ activeIndex, setActiveIndex, documentId }) => {
	const { shiftRight: { active, setActive, width } } = useShift();
	const { hash } = useLocation();
	const { push } = useHistory();
	const { tabletUp } = useBreakpoint();

	const { tabs, contents, indexes } = useMetaInfoTabs(documentId, activeIndex);

	useEffect(() => {
		if (hash && indexes.length) {
			const index = indexes.findIndex(index => index === hash.substr(1));
			if (index !== -1) {
				setActiveIndex(index);
				setActive(true);

				push({
					hash: ""
				});
			}
		}
	}, [hash, indexes, setActiveIndex, setActive, push]);

	return (
		<SideDrawer
			open={active}
			setOpen={setActive}
			width={tabletUp ? width : 450}
			appBarDisabled={!tabletUp}
			variant={tabletUp ? "persistent" : "temporary"}
		>
			<DocumentDrawerHeader setOpen={setActive}/>
			<ActionTabs
				activeIndex={activeIndex}
				setActiveTab={setActiveIndex}
				tabs={tabs}
				variant="scrollable"
			>
				{contents}
			</ActionTabs>
		</SideDrawer>
	);
};

export default DocumentDrawer;
