// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		toolbar: {
			minHeight: "auto"
		},
		inputWrapper: {
			flex: 1,
			position: "relative",
			transition: theme.transitions.create('background-color'),
			borderBottom: `2px solid transparent`,
			paddingBottom: 2
		},
		active: {
			borderColor: theme.palette.primary.main
		},
		input: {
			marginLeft: theme.spacing(1),
			flex: 1,
			fontSize: "0.875rem"
		},
		inputInner: {
			paddingBottom: 6
		},
		icon: {
			position: "absolute",
			top: 0,
			right: 0,
			marginRight: 2,
			marginTop: 1
		}
	})
);

interface Props {
	query: string;
	setQuery(query: string): void;
}

const DocumentTocToolbar: React.FC<Props> = ({ query, setQuery }) => {
	const classes = useStyles();
	const [focus, setFocus] = useState(false);

	return (
		<Toolbar disableGutters variant="dense" className={classes.toolbar}>
			<Box display="flex" className={clsx(classes.inputWrapper, { [classes.active]: query.length > 0 || focus })}>
				<IconButton size="small">
					<SearchIcon/>
				</IconButton>
				<InputBase
					placeholder="Filter document toc"
					value={query}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
					className={classes.input}
					classes={{ input: classes.inputInner }}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
				/>
				{query.length > 0 && (
					<IconButton
						size="small"
						className={classes.icon}
						onClick={() => setQuery("")}
					>
						<CloseIcon/>
					</IconButton>
				)}
			</Box>
		</Toolbar>
	);
};

export default DocumentTocToolbar;
