// Copyright (C) 2020 TANNER AG

import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Divider from "@material-ui/core/Divider";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import { useImage } from "../imageprovider";

const useStyles = makeStyles(theme =>
	createStyles({
		appBar: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
		},
		closeBtn: {
			marginLeft: "auto",
			color: theme.palette.text.secondary
		},
		divider: {
			height: 24,
			margin: theme.spacing(0, 1)
		}
	})
);

interface ActionProps extends IconButtonProps {
	title: string;
}

const Action: React.FC<ActionProps> = ({ title, children, ...iconProps }) => {
	return (
		<Tooltip title={title} enterDelay={500}>
			<IconButton
				color="inherit"
				{...iconProps}
			>
				{children}
			</IconButton>
		</Tooltip>
	);
}

interface Props {
	zoomIn(event: any): void;
	zoomOut(event: any): void;
	resetTransform(event: any): void;
}

const ImageDialogToolbar: React.FC<Props> = ({ zoomIn, zoomOut, resetTransform }) => {
	const classes = useStyles();
	const { activeSource, setSource } = useImage();

	const openInTab = () => window.open(activeSource, "_blank");

	return (
		<AppBar position="relative" className={classes.appBar}>
			<Toolbar variant="dense">
				<Action
					title="Zoom In"
					onClick={zoomIn}
					edge="start"
				>
					<ZoomInIcon/>
				</Action>
				<Action
					title="Zoom Out"
					onClick={zoomOut}
				>
					<ZoomOutIcon/>
				</Action>
				<Action
					title="Reset Zoom"
					onClick={resetTransform}
				>
					<ZoomOutMapIcon/>
				</Action>
				<Divider
					orientation="vertical"
					className={classes.divider}
				/>
				<Action
					title="Open In Tab"
					onClick={openInTab}
				>
					<ImageSearchIcon/>
				</Action>
				<IconButton
					onClick={() => setSource()}
					className={classes.closeBtn}
					edge="end"
				>
					<CloseIcon/>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

export default ImageDialogToolbar;
