// Copyright (C) 2020 TANNER AG

import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42MetadataResponse, DocumentInfo } from "./types";
import { findMetadataValues } from "../utils";
import { MetadataID } from "../const";

const useDocuments = (): { documents: DocumentInfo[] } => {
	const { data: documents } = useServiceFetch<C42MetadataResponse[]>({
		url: `/user/metadata/${MetadataID.Document}/content`
	});

	return {
		documents: documents?.map(document => ({
			id: document.documentid,
			name: findMetadataValues(document.metadata, "title", [document.documentid])[0],
			products: findMetadataValues(document.metadata, "products", []),
			type: findMetadataValues(document.metadata, "type", [""])[0],
			protocol: findMetadataValues(document.metadata, "protocol", [""])[0],
		})) || []
	};
};

export default useDocuments;
