// Copyright (C) 2019 TANNER AG

import React from "react";
import useHTMLParser from "@c42-ui/core/usehtmlparser";
import Container from "@c42-ui/core/container";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useDesign from "./design";
import useHashScroll from "@c42-ui/core/utils/usehashscroll";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { DatabaseID } from "../../../const";

interface Props {
	documentId: string;
}

const SingleContentLoader: React.FC<Props> = React.memo<Props>(({ documentId }) => {
	const { parseHTML } = useHTMLParser();
	const { data } = useServiceFetch<string>({
		url: `/user/documents/${DatabaseID.Content}/${documentId}/content`,
		isText: true,
		isLazy: !Boolean(documentId)
	});

	useDesign();
	useHashScroll();

	return (
		<Container>
			{!data && (
				<Box display="flex" justifyContent="center" mt={8}>
					<CircularProgress/>
				</Box>
			)}
			<div className="content">
				{parseHTML(data?.replace(new RegExp("�", "g"), "") || "")}
			</div>
		</Container>
	);
});

export default SingleContentLoader;
