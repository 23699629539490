// Copyright (C) 2019 TANNER AG

import { useLocation, useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import { SearchMode } from "../const";
import { useSearch } from "./search/searchprovider";

const getFilter = (name: string, search: string): string[] => {
	const params = parse(search);
	const filterParams = params[name];

	if (!filterParams) {
		return [];
	}

	if (!Array.isArray(filterParams)) {
		return [filterParams];
	}

	return filterParams;
};

const useSearchParams = () => {
	const { search } = useLocation();
	const { replace } = useHistory();
	const { fulltextFilterKeys } = useSearch();

	const setQuery = (query: string) => {
		pushParams({
			query
		});
	};

	const setSearchMode = (mode: SearchMode) => {
		const params = parse(search);
		pushParams({ ...params, mode });
	};

	const setFulltextPage = (page: number) => {
		const params = parse(search);
		pushParams({ ...params, page });
	};

	const setFilter = (name: string, value: string, checked: boolean) => {
		const params = parse(search);
		const filterParams = params[name];

		if (!filterParams) {
			pushParams({ ...params, page: 1, [name]: value });
			return;
		}

		if (Array.isArray(filterParams)) {
			const index = filterParams.findIndex(param => param === value);

			if (index === -1) {
				filterParams.push(value);
			} else {
				filterParams.splice(index, 1);
			}

			pushParams({ ...params, page: 1 });
			return;
		}

		if (filterParams === value) {
			pushParams({ ...params, page: 1, [name]: null });
			return;
		}

		pushParams({ ...params, page: 1, [name]: [filterParams, value] });
	};

	const pushParams = (newParams: any) => {
		replace({ search: `?${stringify(newParams)}` });
	};

	const parsedQuery = parse(search);

	const filter: { [id: string]: string[] } = {};
	fulltextFilterKeys.forEach(id => {
		filter[id] = getFilter(id, search);
	});

	return {
		query: parsedQuery.query?.toString() || "",
		searchMode: parsedQuery.mode ? parseInt(parsedQuery.mode.toString()) : SearchMode.AI,
		page: parsedQuery.page ? parseInt(parsedQuery.page.toString()) : 1,
		filter,
		setFilter,
		setQuery,
		setSearchMode,
		setFulltextPage
	};
};

export default useSearchParams;
