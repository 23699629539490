// Copyright (C) 2020 TANNER AG

import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import useProducts from "../../useproducts";
import { useHistory, Link } from "react-router-dom";
import { parse } from "query-string";
import Typography from "@material-ui/core/Typography";
import { MEDIA_SERVER_URL } from "../../../const";
import { textFontFamily } from "../../../theme";
import MaterialLink from "@material-ui/core/Link";
import ActionDrawerHeader from "@c42-ui/core/actiondrawerheader";

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			alignItems: "flex-start",
			minHeight: 130
		},
		imageWrapper: {
			height: 90,
		},
		image: {
			height: "100%"
		},
		title: {
			fontFamily: textFontFamily,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.primary.main
		},
		subTitle: {
			fontFamily: textFontFamily,
		}
	})
);

const DocumentDrawerHeader: React.FC<{ setOpen(open: boolean): void }> = ({ setOpen }) => {
	const classes = useStyles();
	const { products } = useProducts();
	const { location: { search } } = useHistory();
	const { product: productId } = parse(search);

	if (!productId) {
		return (
			<ActionDrawerHeader
				setOpen={() => setOpen(false)}
				title=""
			/>
		);
	}

	const product = products.find(product => product.id === productId);
	if (!product) {
		return (
			<ActionDrawerHeader
				setOpen={() => setOpen(false)}
				title=""
			/>
		);
	}

	return (
		<Toolbar className={classes.root}>
			<Box flexGrow={1} mt={1}>
				<Typography variant="subtitle2" gutterBottom>Produktdetails</Typography>
				<Box display="flex">
					<div className={classes.imageWrapper}>
						<img
							className={classes.image}
							src={`${MEDIA_SERVER_URL}/${product.id}_thumbnail.png`}
							alt={product.name}
						/>
					</div>
					<Box ml={1}>
						<Typography className={classes.title}>{product.name}</Typography>
						<Typography className={classes.subTitle} gutterBottom>{product.description}</Typography>
						<Typography component="p" variant="caption">Number: {product.number}</Typography>
						<MaterialLink variant="caption" component={Link} to={`/products/${product.id}`}>Details Anzeigen</MaterialLink>
					</Box>
				</Box>
			</Box>
			<IconButton
				edge="end"
				color="inherit"
				onClick={() => setOpen(false)}
			>
				<CloseIcon/>
			</IconButton>
		</Toolbar>
	);
};

export default DocumentDrawerHeader;
