// Copyright (C) 2020 TANNER AG

import React from "react";
import { useParams } from "react-router";
import useDocuments from "../../usedocuments";
import DocumentSelection from "./documentselection";

const DocumentActions = () => {
	const { id } = useParams();
	const { documents } = useDocuments();
	const documentId = decodeURIComponent(id || "");

	return (
		<DocumentSelection
			selected={documentId}
			documents={documents}
		/>
	);
};

export default DocumentActions
