// Copyright (C) 2020 TANNER AG

import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42MetadataResponse, ProductInfo } from "./types";
import { findMetadataValues } from "../utils";
import { MetadataID } from "../const";

const useProducts = (): { products: ProductInfo[] } => {
	const { data: products } = useServiceFetch<C42MetadataResponse[]>({
		url: `/user/metadata/${MetadataID.Product}/content`
	});

	return {
		products: products?.map(product => ({
			id: product.documentid,
			name: findMetadataValues(product.metadata, "name", [""])[0],
			description: findMetadataValues(product.metadata, "description", [""])[0],
			number: findMetadataValues(product.metadata, "number", [""])[0],
		})) || []
	};
};

export default useProducts;
