// Copyright (C) 2020 TANNER AG

import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { SearchHit } from "./types";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useSearch } from "../searchprovider";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(2),
			boxShadow: "0 0 0 1px rgba(0, 0, 0, .1)",
			borderRadius: 2
		},
		active: {
			boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
		},
		actions: {
			padding: theme.spacing(1, 2),
			display: "flex",
			flexWrap: "wrap",

			"& > :not(:last-child)": {
				marginRight: theme.spacing(0.5),
				marginBottom: theme.spacing(0.5)
			}
		},
		content: {
			padding: theme.spacing(1, 2)
		},
		breadcrumbs: {
			marginBottom: theme.spacing(1)
		},
		dummyContent: {
			padding: theme.spacing(1, 2),

			"&:last-child": {
				paddingBottom: theme.spacing(2)
			}
		},
		startIcon: {
			marginRight: 0
		}
	})
);

const SearchFulltextItem: React.FC<{ item: SearchHit; index: number }> = ({ item, index }) => {
	const classes = useStyles();
	const { generateLink } = useLinkHandler();
	const { setMatchIndex, matchIndex } = useSearch();

	const to = generateLink({
		toc: item.document.id,
		anchor: item.id
	});

	return (
		<Card
			className={clsx(classes.root, {
				[classes.active]: index === matchIndex
			})}
		>
			<CardActionArea component={Link} to={to}>
				<CardContent className={classes.content}>
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
						className={classes.breadcrumbs}
					>
						{item.chapters.map(({ id, name }) => (
							<Typography key={id} variant="body2" color="textSecondary">
								{name}
							</Typography>
						))}
					</Breadcrumbs>
					<Typography variant="subtitle1">
						<strong>
							{item.document.title} ({item.document.type})
						</strong>
					</Typography>
					<Typography
						variant="h6"
						component="h3"
						color="primary"
						gutterBottom
						dangerouslySetInnerHTML={{ __html: item.name }}
					/>
					<Typography
						variant="body2"
						color="textPrimary"
						component="p"
						dangerouslySetInnerHTML={{ __html: item.highlight?.join("... ") || "" }}
					/>
				</CardContent>
			</CardActionArea>
			<div className={classes.actions}>
				{item.metadata.map(({ id, name, values }) => (
					<Chip
						title={values.join(" | ")}
						size="small"
						color="default"
						variant="outlined"
						key={id}
						label={`${name}: ${values.slice(0, 4).join(" | ")}${
							values.length > 4 ? "..." : ""
						}`}
					/>
				))}
			</div>
			<CardActions className={classes.actions}>
				<Button
					variant="outlined"
					color="primary"
					size="small"
					onClick={() => setMatchIndex(index)}
				>
					Preview
				</Button>
				<Button variant="contained" color="primary" size="small" component={Link} to={to}>
					Open
				</Button>
			</CardActions>
		</Card>
	);
};

export default SearchFulltextItem;
