// Copyright (C) 2020 TANNER AG

import React from "react";
import { TocItem } from "@c42-ui/core/types";
import FragmentLoader from "./fragmentloader";

interface Props {
	documentId: string;
	section: TocItem;
}

const ChapterLoader = React.memo<Props>(({ documentId, section }) => {
	// TODO currently only first level chapter are used. Change to generic toc fragmentation with `readTocItems`
	const fragments: string[] = [section.target.content || ""];
	return (
		<>
			{fragments.map((fragment: string) => (
				<FragmentLoader
					key={fragment}
					documentId={documentId}
					fragmentId={fragment}
				/>
			))}
		</>
	);
});

export default ChapterLoader;
