// Copyright (C) 2020 TANNER AG

import React from "react";
import HomeItem from "../item";
import useProducts from "../../useproducts";

const HomeItems = () => {
	const { products } = useProducts();

	if (!products) {
		return null;
	}

	return (
		<>
			{products.map(product => (
				<HomeItem
					key={product.id}
					id={product.id}
					product={product}
				/>
			))}
		</>
	);
};

export default HomeItems;
