// Copyright (C) 2020 TANNER AG

import * as React from "react";
import { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { Link } from "react-router-dom";
import { Touchpoint, TouchpointGroup } from "./tasks";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TASK_GROUP_LABELS } from "../../../const";
import { LinkTarget } from "@c42-ui/core/types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		nested: {
			paddingLeft: theme.spacing(6),
		}
	})
);

export const TaskItem: React.FC<{ touchpoint: Touchpoint, className?: string }> = ({ touchpoint, className }) => {
	const { generateLink } = useLinkHandler();
	const target: LinkTarget = {
		toc: touchpoint.document,
		anchor: touchpoint.anchor,
		params: { product: touchpoint.product || "", task: touchpoint.task, protocol: touchpoint.protocol }
	};

	return (
		<ListItem
			button
			dense
			component={Link}
			to={generateLink(target)}
			className={className}
		>
			<ListItemText primary={touchpoint.name}/>
		</ListItem>
	);
};

export const TaskItemGroup: React.FC<{ group: TouchpointGroup }> = ({ group: { name, items } }) => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	return (
		<>
			<ListItem button dense onClick={() => setOpen(!open)}>
				<ListItemText primary={TASK_GROUP_LABELS[name] || name}/>
				{open ? <ExpandLess/> : <ExpandMore/>}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{items.map(item => (
						<TaskItem
							key={item.name}
							touchpoint={item}
							className={classes.nested}
						/>
					))}
				</List>
			</Collapse>
		</>
	);
};
