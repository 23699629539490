// Copyright (C) 2020 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Container from "@c42-ui/core/container";
import Product from "./product";
import DocumentActions from "./documentactions";
import useShift from "@c42-ui/core/useshift";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useBreakpoint from "../../usebreakpoint";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import DocumentControls from "../controls";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			top: 56,
			transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",

			[theme.breakpoints.up("sm")]: {
				top: 64
			}
		},
		appBarTop: {
			transform: "translateY(-64px)"
		},
		root: {
			paddingTop: 0,
			paddingBottom: 0,
			display: "flex",
			alignItems: "center",
			minHeight: 42
		},
		breadcrumbs: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			flexGrow: 1
		},
		icon: {
			width: 17,
			height: 17,
			marginRight: theme.spacing(0.5),
		},
		typo: {
			fontFamily: "'Lato'",
			lineHeight: "1.2",
			display: "flex",
			fontWeight: theme.typography.fontWeightBold
		}
	})
);

const DocumentInfoBar: React.FC = () => {
	const classes = useStyles();
	const { tabletUp } = useBreakpoint();
	const { shiftRight: { setActive, active } } = useShift();

	return (
		<>
			<AppBar
				position="fixed"
				color="default"
				className={clsx(classes.appBar)}
			>
				<Container className={classes.root}>
					<Box flexGrow={1} alignItems="center">
						{tabletUp && (
							<DocumentActions/>
						)}
						{!tabletUp && (
							<IconButton
								color="primary"
								onClick={() => setActive(true)}
								edge="start"
							>
								<InfoIcon/>
							</IconButton>
						)}
					</Box>
					{!active && (
						<Box>
							{tabletUp && (
								<Button
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => setActive(true)}
								>
									Zusätzliche Info
								</Button>
							)}
						</Box>
					)}
					<Product/>
					<DocumentControls/>
				</Container>
			</AppBar>
			<div style={{ height: 42 }}/>
		</>
	);
};

export default DocumentInfoBar;
