// Copyright (C) 2020 TANNER AG

import { TocItem } from "@c42-ui/core/types";
import { DataAttribute } from "@c42-ui/core";
import clsx from "clsx";
import { decodeHtmlEntity, extractEnum } from "../../utils";

export const buildTocAction = (item: TocItem) => {
	if (!item.nodes.length) {
		return "";
	}

	return `
		<div class="tocListItemActionContainer">
			<button data-target="#${item.target.anchor}" class="tocListItemAction MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall">
				<span class="MuiIconButton-label">
					<svg xmlns="http://www.w3.org/2000/svg" class="MuiSvgIcon-root tocListItemActionClose" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
						<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
					</svg>
					<svg xmlns="http://www.w3.org/2000/svg" class="MuiSvgIcon-root tocListItemActionOpen" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
						<path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
					</svg>
				</span>
			</button>
		</div>
	`;
};

export const buildTocEntry = (item: TocItem): string => {
	const [listNumber, label] = extractEnum(item.label);
	const linkProps = [
		["class", clsx("nav-link", "tocListItem", { "open": item.open })],
		["href", "#"],
		[DataAttribute.toc, item.target.toc],
		[DataAttribute.content, item.target.content],
		[DataAttribute.target, `#${item.target.anchor}`],
	];

	return `
		<li class="tocListItemContainer">
			<a ${linkProps.map(prop => `${prop[0]}="${prop[1]}"`).join(" ")}>
				<div class="tocListItemTextContainer">
					<div class="tocListItemText">
						<span class="tocListItemNumber">${listNumber}</span>
						<span class="tocListItemLabel">${decodeHtmlEntity(label)}</span>
					</div>
				</div>
			</a>
			<ul class="tocList">
				${item.nodes.map(item => buildTocEntry(item)).join("")}
			</ul>
			${buildTocAction(item)}
		</li>
	`;
};
