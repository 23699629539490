// Copyright (C) 2020 TANNER AG

import React from "react";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginRight: theme.spacing(0.5),
			marginBottom: theme.spacing(0.5)
		}
	})
);

interface Props {
	label: string;
	value: string;
	checked?: boolean;
	onChange(value: string, checked: boolean): void;
}

const FilterChip: React.FC<Props> = ({ label, value, onChange, checked }) => {
	const classes = useStyles();

	return (
		<Chip
			label={label}
			variant="outlined"
			className={classes.root}
			component="button"
			color={checked ? "primary" : "default"}
			onClick={() => onChange(value, !checked)}
			clickable
		/>
	);
};

export default FilterChip;
