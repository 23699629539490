// Copyright (C) 2020 TANNER AG

import React from "react";
import { useHistory } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Omnibox from "../../omnibox";
import useBreakpoint from "../../usebreakpoint";
import clsx from "clsx";
import useTranslation from "@c42-ui/core/usetranslation";
import SearchTabbar from "../tabbar";
import { SearchContainer } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.secondary,
			zIndex: theme.zIndex.drawer + 1
		},
		toolbar: {
			justifyContent: "space-between"
		},
		title: {
			marginLeft: theme.spacing(1),
			alignItems: "center",
			display: "flex"
		},
		searchAbsolute: {
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			position: "absolute"
		}
	})
);

const SearchAppbar: React.FC<{ lastPage: string }> = ({ lastPage }) => {
	const classes = useStyles();
	const { tabletUp } = useBreakpoint();
	const { push } = useHistory();
	const { t } = useTranslation();

	return (
		<AppBar className={classes.appBar} position="sticky">
			<Toolbar className={classes.toolbar}>
				<Box display="flex">
					{tabletUp && (
						<Typography variant="h6" className={classes.title}>
							{t("search.title")}
						</Typography>
					)}
				</Box>
				<SearchContainer className={clsx({ [classes.searchAbsolute]: tabletUp })}>
					<Omnibox global={false}/>
				</SearchContainer>
				<IconButton
					edge="end"
					color="inherit"
					onClick={() => push(lastPage)}
				>
					<CloseIcon/>
				</IconButton>
			</Toolbar>
			<SearchTabbar/>
		</AppBar>
	);
};

export default SearchAppbar;
