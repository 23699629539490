// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";
import useDocumentTouchpoints from "../usedocumenttouchpoints";
import Box from "@material-ui/core/Box";
import { C42TouchpointResponse, DocumentInfo } from "../../types";
import { DOCUMENT_TYPE_LABELS } from "../../../const";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useHashLink } from "@c42-ui/core/utils/usehashscroll";
import { parse } from "query-string";

const useStyles = makeStyles(theme =>
	createStyles({
		action: {
			textTransform: "none",
			minWidth: 0
		}
	})
);

const DocumentSelection: React.FC<{ selected: string, documents: DocumentInfo[] }> = ({ selected, documents }) => {
	const classes = useStyles();
	const touchpoints = useDocumentTouchpoints();
	const { location: { search }, push } = useHistory();
	const executeHashScroll = useHashLink();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const { task: taskId } = parse(search);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const execute = (touchpoint: C42TouchpointResponse) => {
		handleClose();
		push(`/documents/${touchpoint.documentid}${search}#${touchpoint.anchorid}`);
		executeHashScroll(touchpoint.anchorid);
	}

	const open = Boolean(anchorEl);

	const mappedTouchpoints = touchpoints.map(touchpoint => ({
		touchpoint,
		document: documents.find(document => document.id === touchpoint.documentid)
	}));

	const selectedDocument = documents.find(document => document.id === selected);

	return (
		<Box display="flex">
			<Box mr={1}>
				<Button
					fullWidth
					className={classes.action}
					variant="text"
					size="small"
					onClick={handleClick}
					endIcon={<ArrowDropDownIcon/>}
					disabled={touchpoints.length <= 1}
				>
					{selectedDocument?.name} - {DOCUMENT_TYPE_LABELS[selectedDocument?.type || ""]}
				</Button>
				<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
					<List dense>
						{mappedTouchpoints.map(({ document, touchpoint }, index) => (
							<ListItem
								key={index}
								selected={selected === touchpoint.documentid}
								onClick={() => execute(touchpoint)}
								button
							>
								<ListItemText primary={`${document?.name} - ${DOCUMENT_TYPE_LABELS[document?.type || ""]}`}/>
							</ListItem>
						))}
					</List>
				</Popover>
			</Box>
			{taskId === "product_connect_fieldbus" && (
				<Button
					variant="text"
					size="small"
					disabled
					endIcon={<ArrowDropDownIcon/>}
				>
					Protokoll: Profinet
				</Button>
			)}
		</Box>
	);
};

export default DocumentSelection;
