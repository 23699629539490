// Copyright (C) 2020 TANNER AG

import useProducts from "./useproducts";
import { AutoSuggestRequestParams, Option } from "@c42-ui/core/types";
import { MEDIA_SERVER_URL } from "../const";

const useAutoSuggest = () => {
	const { products } = useProducts();

	const fetchAutoSuggest = async(params: AutoSuggestRequestParams): Promise<Option[]> => {
		const query = params.value.trim().toLocaleLowerCase();
		const length = query.length;

		return products
			.filter(product => !length
				|| product.name.toLocaleLowerCase().includes(query)
				|| product.description.toLocaleLowerCase().includes(query)
				|| product.number.toLocaleLowerCase().includes(query)
				|| product.id.toLocaleLowerCase().includes(query)
			)
			.slice(0, 5)
			.map(product => ({
				id: product.id,
				label: product.name,
				description: product.description,
				thumbnail: `${MEDIA_SERVER_URL}/${product.id}_thumbnail.png`,
				target: {
					content: product.id,
					type: "product"
				},
				type: "suggest"
			}));
	};

	return {
		fetchAutoSuggest
	};
};

export default useAutoSuggest;
