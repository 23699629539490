// Copyright (C) 2020 TANNER AG

import React from "react";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useShift from "@c42-ui/core/useshift";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: "fixed",
			bottom: theme.spacing(1),
			right: theme.spacing(1),

			[theme.breakpoints.up("lg")]: {
				right: theme.spacing(8)
			}
		},
		shift: {
			right: (width: number) => width + 24
		}
	}),
);

const BackToTop: React.FC = () => {
	const { shiftRight: { width, active } } = useShift();
	const classes = useStyles(width);
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
			"#back-to-top-anchor",
		);

		if (anchor) {
			anchor.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	return (
		<Zoom in={trigger}>
			<div onClick={handleClick} role="presentation" className={clsx(classes.root, { [classes.shift]: active })}>
				<Fab color="primary" size="small" aria-label="scroll back to top">
					<KeyboardArrowUpIcon/>
				</Fab>
			</div>
		</Zoom>
	);
};

export default BackToTop;
