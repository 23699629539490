// Copyright (C) 2020 TANNER AG

import React from "react";
import { TocItem } from "@c42-ui/core/types";

interface DocumentContextProps {
	document?: string,
	tocItems?: TocItem[];
	inDocument: boolean;
	setDocument(document?: string): void;
	setTocItems(tocItems?: TocItem[]): void;
}

const DocumentContext = React.createContext<DocumentContextProps>({
	inDocument: false,
	setDocument: () => null,
	setTocItems: () => null
});

export default DocumentContext;
