// Copyright (C) 2019 TANNER AG

import React from "react";
import Container from "@c42-ui/core/container";
import { useC42 } from "@c42-ui/core";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import HomeItems from "./items";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: "flex",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				alignItems: "flex-start"
			}
		},
		searchWrapper: {
			maxWidth: 600
		},
		searchContainer: {
			zIndex: 1
		},
		content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: 50,
			[theme.breakpoints.down("sm")]: {
				marginTop: 0
			}
		},
		logoWrapper: {
			display: "flex",
			justifyContent: "center",
			width: "100%",
			maxWidth: 300,
			marginTop: theme.spacing(4),

			[theme.breakpoints.down("sm")]: {
				display: "none"
			}
		},
		logo: {
			width: "100%"
		},
		contentWrapper: {
			width: "100%",
			maxWidth: 600,

			[theme.breakpoints.up("md")]: {
				marginTop: theme.spacing(4)
			}
		}
	})
);

const Home = () => {
	const classes = useStyles();
	const { setup: { app: { basename, logo, name } } } = useC42();

	return (
		<Box className={classes.wrapper}>
			<Container gutterTop className={classes.content}>
				<div className={classes.logoWrapper}>
					<img className={classes.logo} src={basename + logo} alt={name}/>
				</div>
				<div className={classes.contentWrapper}>
					<Grid container spacing={2}>
						<HomeItems/>
					</Grid>
				</div>
			</Container>
		</Box>
	);
};

export default Home;
