// Copyright (C) 2020 TANNER AG

import React from "react";
import { useImage } from "../imageprovider";
import Dialog from "@material-ui/core/Dialog";
import useBreakpoint from "../usebreakpoint";
import ImagePanZoom from "./imagepanzoom";

const ImageDialog: React.FC = () => {
	const { spDown } = useBreakpoint();
	const { activeSource, setSource } = useImage();

	if (!activeSource) {
		return null;
	}

	return (
		<Dialog
			maxWidth="xl"
			fullWidth
			fullScreen={spDown}
			onClose={() => setSource()}
			open={Boolean(activeSource)}
		>
			<ImagePanZoom src={activeSource}/>
		</Dialog>
	);
};

export default ImageDialog;
