// Copyright (C) 2020 TANNER AG

export const MEDIA_SERVER_URL = process.env.REACT_APP_MEDIA_URL;

// TODO temp - until fragmentation for all documents are supported by services
export const FRAGMENTATION_DOCUMENTS = ["DYXML_C_CMMT-AS-SW, 6, de_DE"];

// TODO temp use in services
export const PROTOCOL_ENTITIES = ["Profinet", "Ethercat", "TCP/IP"];

// TODO temp use in services
export const TASK_GROUP_LABELS: { [intent: string]: string } = {
	fault: "Störung",
	repair: "Reparatur",
	product_connect: "Produkt anschließen"
};

// TODO temp use in services
export const DOCUMENT_TYPE_LABELS: { [intent: string]: string } = {
	manual: "Bedienungsanleitung",
	reparation: "Reparatur",
	"application-note": "Application Note",
	quickguide: "Quick Guide"
};

export enum SearchMode {
	AI,
	Fulltext
}

export enum MetadataID {
	Document = "documents",
	Product = "products",
	Task = "tasks"
}

export enum DatabaseID {
	Content = "c42-content",
	Index = "c42-index"
}
