// Copyright (C) 2020 TANNER AG

import React from "react"
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SpellcheckQuery } from "./types";

const useStyles = makeStyles(theme =>
	createStyles({
		fixedLink: {
			marginLeft: theme.spacing(1)
		},
		fixedQuery: {
			fontSize: "1.1rem"
		}
	}),
);

const SpellCheck: React.FC<{ fixedQueries?: SpellcheckQuery[] }> = ({ fixedQueries }) => {
	const classes = useStyles();

	if (!fixedQueries?.length) {
		return null;
	}

	const fixedQuery = fixedQueries.map(q => q.query).join(" ");

	return (
		<Box mb={2}>
			<Typography variant="body2">
				Meinten Sie
				<Link
					to={`/search?query=${fixedQuery}`}
					className={classes.fixedLink}
				>
					<Typography
						variant="h6"
						component="strong"
						className={classes.fixedQuery}
					>
						{fixedQueries.map(q => q.suggestion ? (
							<i key={q.query}>{q.query} </i>
						) : (
							<span key={q.query}>{q.query} </span>
						))}
					</Typography>
				</Link>
			</Typography>
		</Box>
	);
};

export default SpellCheck;
