// Copyright (C) 2020 TANNER AG

import React, { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TabIndex } from "../index";
import useBreakpoint from "../../usebreakpoint";
import useDocument from "../../document/provider";

interface Props {
	index: TabIndex;
	setIndex(index: TabIndex): void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tab: {
			minWidth: 30
		}
	})
);

const NavigationTabs: React.FC<Props> = ({ index, setIndex }) => {
	const classes = useStyles();
	const { tabletUp } = useBreakpoint();
	const { inDocument } = useDocument();

	useEffect(() => {
		if (index === TabIndex.Document && (tabletUp || !inDocument)) {
			setIndex(TabIndex.Document);
		}
	}, [index, inDocument, tabletUp, setIndex]);

	if (tabletUp || !inDocument) {
		return null;
	}

	return (
		<Tabs
			value={index}
			onChange={(_: unknown, value: TabIndex) => setIndex(value)}
			indicatorColor="primary"
			variant="fullWidth"
		>
			<Tab label="Products" className={classes.tab}/>
			<Tab label="Document"/>
		</Tabs>
	);
};

export default NavigationTabs;
