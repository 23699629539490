// Copyright (C) 2019 TANNER AG

import React from "react";
import { TocItem } from "@c42-ui/core/types";
import useTreeFilter from "@c42-ui/core/utils/usetreefilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { ScrollItem } from "@c42-ui/core/usetocbuilder/usetocbuilder";
import useTocBuilder from "../../usetocbuilder";

interface Props {
	id: string;
	tocItems: TocItem[];
	loading?: boolean;
	query: string;
	onTargetClick?(): void;
}

const DocumentToc: React.FC<Props> = ({ id, tocItems, loading, query, onTargetClick }) => {
	const { filteredItems } = useTreeFilter(tocItems || [], query);
	useTocBuilder({
		id,
		offset: 120,
		items: filteredItems,
		scrollToc: true,
		update: true,
		onTargetClick,
		onActivate(item: ScrollItem): void {
			const root = document.getElementById("warnings");

			if (root) {
				let forcedRoot = document.getElementById("warnings-forced");
				if (!forcedRoot) {
					root.innerHTML = "<div id='warnings-forced'></div>" + root.innerHTML;
					forcedRoot = document.getElementById("warnings-forced");
				}

				if (forcedRoot) {
					forcedRoot.innerHTML = "";

					const nodes: HTMLElement[] = [].slice
						.call(root.querySelectorAll(item.elements.map(element => `[data-parent-id='${element}']`)
							.join(",")));

					if (nodes.length) {
						forcedRoot.innerHTML = "<p style='margin-top: 1rem'><strong>Warnhinweise für das aktuelle Kapitel:</strong></p>";

						nodes.forEach((element: HTMLElement) => {
							if (forcedRoot) {
								forcedRoot.appendChild(element.cloneNode(true));
							}
						});

						forcedRoot.innerHTML = forcedRoot.innerHTML + "<div style='border-bottom: 3px solid #b7b7b7; margin: 0.5rem 0;'></div>";
					}
				}
			}
		}
	});

	return (
		<>
			{loading && (
				<Box display="flex" justifyContent="center" m={2}>
					<CircularProgress/>
				</Box>
			)}
			<ul id={id} className="tocList rootTocList"/>
		</>
	);
};

export default DocumentToc;
