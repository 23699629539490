// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";
import DocumentDrawer from "./drawer";
import { ActionTabIndex } from "./drawer/documentdrawer";
import { useParams } from "react-router";
import ShiftProvider from "@c42-ui/core/shiftprovider";
import BackToTop from "../backtotop";
import useDocumentTocFetch from "./usedocumenttocfetch";
import { useDocumentSession } from "./provider/usedocument";
import DocumentInfoBar from "./infobar";
import DocumentTocDrawer from "./toc/drawer";
import ImageProvider from "../imageprovider";
import ImageDialog from "../imagedialog";
import useBreakpoint from "../usebreakpoint";
import DocumentLoader from "./content/documentloader";
import Progress from "@c42-ui/core/progress";
import SingleContentLoader from "./content/singlecontentloader";
import { FRAGMENTATION_DOCUMENTS } from "../../const";

const Document = () => {
	const { tabletUp } = useBreakpoint();
	const [tabIndex, setTabIndex] = useState(ActionTabIndex.Search);
	const { id } = useParams();
	const documentId = decodeURIComponent(id || "");
	const { tocItems, loading } = useDocumentTocFetch(documentId);

	useDocumentSession(documentId, tocItems, loading);

	if (loading) {
		return <Progress/>;
	}

	return (
		<ShiftProvider
			shiftLeftWidth={tabletUp ? 350 : 0}
			shiftRightWidth={tabletUp ? 550 : 0}
			shiftLeftOpen
		>
			<ImageProvider>
				<DocumentInfoBar/>
				<DocumentTocDrawer tocItems={tocItems}/>
				{FRAGMENTATION_DOCUMENTS.includes(documentId) ? (
					<DocumentLoader tocItems={tocItems} documentId={documentId}/>
				) : (
					<SingleContentLoader documentId={documentId}/>
				)}
				<DocumentDrawer
					activeIndex={tabIndex}
					setActiveIndex={setTabIndex}
					documentId={documentId}
				/>
				<BackToTop/>
				<ImageDialog/>
			</ImageProvider>
		</ShiftProvider>
	);
};

export default Document;
