// Copyright (C) 2020 TANNER AG

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useSearchParams from "../../usesearchparams";
import useBreakpoint from "../../usebreakpoint";
import IconButton from "@material-ui/core/IconButton";
import TuneIcon from "@material-ui/icons/Tune";
import useShift from "@c42-ui/core/useshift";
import { SearchMode } from "../../../const";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginLeft: "auto"
		}
	})
);

const SearchTabbarFilterAction = () => {
	const classes = useStyles();
	const { shiftLeft: { setActive } } = useShift();
	const { tabletUp } = useBreakpoint();
	const { searchMode, filter } = useSearchParams();

	if (tabletUp || searchMode !== SearchMode.Fulltext) {
		return null;
	}

	const filterCount = Object.entries(filter)
		.map(([key, values]) => {
			return values.length
		})
		.reduce((acc, cur) => acc + cur, 0);

	return (
		<IconButton
			className={classes.root}
			onClick={() => setActive(true)}
			edge="end"
		>
			<Badge
				badgeContent={filterCount}
				color="primary"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<TuneIcon/>
			</Badge>
		</IconButton>
	);
};

export default SearchTabbarFilterAction;
