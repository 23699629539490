// Copyright (C) 2019 TANNER AG

import React from "react";
import Container from "@c42-ui/core/container";
import { useParams } from "react-router";
import ProductDocuments from "./documents";
import ProductTasks from "./tasks";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MEDIA_SERVER_URL } from "../../const";
import useProducts from "../useproducts";
import { textFontFamily } from "../../theme";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		imageContainer: {
			textAlign: "center"
		},
		image: {
			maxWidth: "90%",
			height: "auto"
		},
		nested: {
			paddingLeft: theme.spacing(6),
		},
		heading: {
			fontWeight: 700,
			letterSpacing: "0.03em",
			fontFamily: textFontFamily
		},
		"@global": {
			"body": {
				backgroundColor: "#f7f7f7"
			}
		}
	})
);

const Product = () => {
	const { id } = useParams();
	const classes = useStyles();
	const { products } = useProducts();
	const productId = decodeURIComponent(id || "");

	const product = products.find(product => product.id === productId);
	if (!product) {
		return null;
	}

	return (
		<Container gutterTop>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<Box mb={2}>
						<Typography
							variant="h4"
							component="h1"
							color="primary"
							className={classes.heading}
						>
							{product?.name}
						</Typography>
						<Typography
							variant="h6"
							component="h2"
							color="textSecondary"
							className={classes.heading}
							gutterBottom
						>
							{product?.description}
						</Typography>
					</Box>
					<ProductTasks id={productId}/>
					<ProductDocuments id={productId}/>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.imageContainer}>
					<img
						className={classes.image}
						alt={productId}
						src={`${MEDIA_SERVER_URL}/${productId}.png`}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Product;
