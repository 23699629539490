// Copyright (C) 2020 TANNER AG

import { useHistory } from "react-router-dom";
import { parse } from "query-string";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42TouchpointResponse } from "../types";
import { stringify } from "query-string";

const useDocumentTouchpoints = () => {
	const { location: { search } } = useHistory();
	const { product: productId, task: taskId } = parse(search);

	const params = stringify({
		filter: [`product:${productId}`, `task:${taskId}`]
	});

	const { data: touchpoints } = useServiceFetch<C42TouchpointResponse[]>({
		url: `/user/touchpoints/document/content?${params}`,
		isLazy: !productId || !taskId
	});

	return touchpoints || [];
};

export default useDocumentTouchpoints;
