// Copyright (C) 2019 TANNER AG

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useDesign = makeStyles((theme: Theme) =>
	createStyles({
		"@global": {
			".content": {

				"& table": {
					maxWidth: "100%",
					display: "table",
					borderSpacing: 0,
					borderCollapse: "collapse",
					marginBottom: theme.spacing(2),

					"& thead": {
						display: "table-header-group",

						"& tr": {
							background: theme.palette.grey["100"],

							"& td, & th": {

							}
						}
					},

					"& tbody": {
						display: "table-row-group",

						"& tr": {

							"& td, & th": {

							}
						}
					},

					"& tr": {
						color: "inherit",
						display: "table-row",
						outline: 0,
						verticalAlign: "middle"
					},

					"& td, & th": {
						display: "table-cell",
						padding: theme.spacing(2),
						textAlign: "left",
						letterSpacing: "0.01071em",
						verticalAlign: "inherit",
						border: "1px solid rgba(224, 224, 224, 1)",

						"& > p": {
							margin: 0
						}
					},
				}
			}
		}
	})
);

export default useDesign;
