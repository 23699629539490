// Copyright (C) 2020 TANNER AG

import React, { useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import useTouchPoints from "../../usetouchpoints";
import useSearchParams from "../../usesearchparams";
import useLuis from "./useluis";
import SearchAIActions from "./searchaiactions";
import SearchAITasks from "./searchaitasks";
import { PROTOCOL_ENTITIES } from "../../../const";
import SpellCheck from "./spellcheck";
import { SearchContainer } from "../utils";
import SearchProgress from "../fulltext/searchprogress";
import useProducts from "../../useproducts";
import useTasks from "../../usetasks";
import SearchAIProducts from "./searchaiproducts";
import SearchAIProtocols from "./searchaiprotocols";

interface SearchItemProps {
	label: string;
	selected?: boolean;
	disabled?: boolean;
	onClick?(): void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		itemTypoSelected: {
			fontWeight: theme.typography.fontWeightBold
		},
		title: {
			fontFamily: "monospace",
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "1.1rem",
			color: theme.palette.primary.main
		},
		selected: {
			backgroundColor: `${fade(theme.palette.primary.main, 0.1)} !important`
		}
	})
);

export const SearchItem: React.FC<SearchItemProps> = ({ label, selected, onClick, disabled }) => {
	const classes = useStyles();

	return (
		<ListItem
			button
			selected={selected}
			onClick={onClick}
			classes={{ selected: classes.selected }}
			disabled={disabled}
		>
			<ListItemText
				primary={label}
				primaryTypographyProps={{
					className: clsx({ [classes.itemTypoSelected]: selected })
				}}
			/>
			{selected && (
				<ListItemSecondaryAction style={{ display: "flex" }}>
					<CheckIcon color="primary" />
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};

const SearchAI = () => {
	const [selectedProduct, setProduct] = useState<string>();
	const [selectedProtocol, setProtocol] = useState<string>();
	const [selectedTask, setTask] = useState<string>();

	const { query } = useSearchParams();
	const { tasks } = useTasks();
	const { products } = useProducts();
	const {
		intent,
		product: foundProduct,
		protocol: foundProtocol,
		fixedQueries,
		loading
	} = useLuis(query, products);
	const { metadata, touchpoints } = useTouchPoints(
		intent,
		{
			product: selectedProduct || "",
			protocol: selectedProtocol || "",
			task: selectedTask || ""
		},
		tasks
	);

	const taskId = metadata?.tasks.length === 1 ? metadata?.tasks[0].id : undefined;

	useEffect(() => {
		setProtocol(foundProtocol);
		setProduct(foundProduct);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [foundProduct, foundProtocol, query]);

	useEffect(() => {
		setTask(taskId);
	}, [query, taskId]);

	if (loading) {
		return <SearchProgress />;
	}

	return (
		<SearchContainer>
			<SpellCheck fixedQueries={fixedQueries} />
			<Box mb={4}>
				<Paper>
					<SearchAIProducts
						productOptions={metadata.products || []}
						products={products}
						onClick={setProduct}
						selected={selectedProduct}
					/>
				</Paper>
			</Box>
			<Box mb={4}>
				<Paper>
					<SearchAITasks
						taskOptions={metadata?.tasks || []}
						tasks={tasks}
						selected={selectedTask}
						onClick={setTask}
						intent={intent}
					/>
				</Paper>
			</Box>
			{selectedTask === "product_connect_fieldbus" && (
				<Box mb={4}>
					<Paper>
						<SearchAIProtocols
							protocols={PROTOCOL_ENTITIES}
							onClick={setProtocol}
							selected={selectedProtocol}
						/>
					</Paper>
				</Box>
			)}
			<SearchAIActions
				touchpoints={touchpoints}
				product={selectedProduct}
				task={selectedTask}
			/>
		</SearchContainer>
	);
};

export default SearchAI;
