// Copyright (C) 2020 TANNER AG

import React, { useEffect } from "react";
import DocumentContext from "./documentcontext";
import { TocItem } from "@c42-ui/core/types";

const useDocument = () => React.useContext(DocumentContext);

export const useDocumentSession = (document?: string, tocItems?: TocItem[], loading?: boolean) => {
	const { setDocument, setTocItems, document: sessionDocument } = useDocument();
	// const printnumber = document?.printnumber;

	useEffect(() => {
		if (!sessionDocument || document !== sessionDocument) {
			setDocument(document);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document]);

	useEffect(() => {
		if (!loading) {
			setTocItems(tocItems);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, tocItems]);

	useEffect(() => {
		return () => {
			setDocument();
			setTocItems();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useDocument;
