// Copyright (C) 2019 TANNER AG

import { useEffect, useReducer } from "react";
import { HttpMethod } from "@c42-ui/core/consts";

export interface FetchData<T = any, E = any> {
	data: T | null,
	loading: boolean;
	error: E | Error | null;
}

export interface FetchResponse<T = any, E = any> extends FetchData<T, E> {
	lazyFetch(): void;
}

export interface FetchOptions {
	url: string;
	method?: HttpMethod | string;
	body?: string;
	headers?: Headers | { [key: string]: string }
	isText?: boolean;
	isLazy?: boolean;
	onSuccess?(data: FetchData, response: Response): void;
	onError?(data: FetchData, response: Response): void;
}

enum ActionType {
	START_FETCH,
	SET_DATA,
	SET_ERROR
}

type Action =
	| { type: ActionType.START_FETCH }
	| { type: ActionType.SET_DATA; payload: any }
	| { type: ActionType.SET_ERROR; payload: string };

const fetchReducer = (state: FetchData, action: Action): FetchData => {
	switch (action.type) {
		case ActionType.START_FETCH:
			return {
				...state,
				loading: true,
				error: null
			};
		case ActionType.SET_DATA:
			return {
				...state,
				loading: false,
				error: null,
				data: action.payload
			};
		case ActionType.SET_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			};
		default:
			return state;
	}
};

const useFetch = <T, E = any>(fetchOptions: FetchOptions): FetchResponse<T, E> => {
	const {
		url,
		method = HttpMethod.GET,
		body = undefined,
		headers = {},
		isText = false,
		isLazy = false,
		onSuccess,
		onError
	} = fetchOptions;

	const [state, dispatch] = useReducer(fetchReducer, {
		data: null,
		loading: !isLazy,
		error: null
	});

	const setFetching = () => {
		dispatch({ type: ActionType.START_FETCH });
	};

	const setError = (error: any) => {
		dispatch({ type: ActionType.SET_ERROR, payload: error });
	};

	const setData = (data: any) => {
		dispatch({ type: ActionType.SET_DATA, payload: data });
	};

	const doFetch = async() => {
		setFetching();

		try {
			const response = await fetch(url, {
				method,
				body,
				headers
			});

			let data;

			if (isText) {
				data = await response.text();
			} else {
				data = await response.json();
			}

			if (!response.ok) {
				setError(data);

				onError && onError(state, response);
			} else {
				setData(data);

				onSuccess && onSuccess(state, response);
			}
		} catch (error) {
			setError(error);

			onError && onError(state, new Response());
		}
	};

	const lazyFetch = async() => {
		if (state.loading) {
			return;
		}

		await doFetch();

		return state.data as T;
	};

	useEffect(() => {
		if (isLazy) {
			return;
		}

		doFetch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url, body, isLazy]);

	return {
		data: state.data as T,
		error: state.error as E,
		loading: state.loading,
		lazyFetch
	};
};

export default useFetch;
