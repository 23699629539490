// Copyright (C) 2020 TANNER AG

import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42MetadataResponse, TaskInfo } from "./types";
import { findMetadataValues } from "../utils";
import { MetadataID } from "../const";

const useTasks = (): { tasks: TaskInfo[] } => {
	const { data: tasks } = useServiceFetch<C42MetadataResponse[]>({
		url: `/user/metadata/${MetadataID.Task}/content`
	});

	return {
		tasks: tasks?.map(task => ({
			id: task.documentid,
			name: findMetadataValues(task.metadata, "name", [""])[0],
			intent: findMetadataValues(task.metadata, "intent", [""])[0],
			group: findMetadataValues(task.metadata, "group", [""])[0],
		})) || []
	};
};

export default useTasks;
