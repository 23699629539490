// Copyright (C) 2020 TANNER AG

import React from "react";
import useHTMLParser from "@c42-ui/core/usehtmlparser";
import Skeleton from "@material-ui/lab/Skeleton";
import useFetch from "@c42-ui/core/utils/usefetch";
import useAuth from "@c42-ui/core/useauth";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { HttpHeader } from "@c42-ui/core/consts";
import { DatabaseID } from "../../../const";

const DummyLoader = () => (
	<>
		{Array.from(new Array(4)).map((item, index) => (
			<div key={`${item}${index}`} style={{ marginTop: "2rem" }}>
				<Skeleton height={18} width="80%"/>
				{Array.from(new Array(6)).map((item, index) => <Skeleton key={`${item}${index}`} height={6}/>)}
			</div>
		))}
	</>
);

interface Props {
	documentId: string;
	fragmentId: string;
}

const FragmentLoader: React.FC<Props> =  React.memo<Props>(({ documentId, fragmentId }) => {
	const { parseHTML } = useHTMLParser();
	const { service: { domain } } = useLinkHandler();
	const { getBearer } = useAuth();
	const { data, loading } = useFetch<string>({
		url: `${domain}/c42-core/api/v1/cache/documents/${DatabaseID.Content}/${documentId}/fragments/${fragmentId}`,
		headers: { [HttpHeader.Authorization]: getBearer() },
		isText: true,
		isLazy: !Boolean(fragmentId)
	});

	if (loading || !data) {
		return <DummyLoader/>
	}

	return (
		<div id={fragmentId}>{parseHTML(data)}</div>
	);
});

export default FragmentLoader;
