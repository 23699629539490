// Copyright (C) 2020 TANNER AG

import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { C42TouchpointResponse } from "../../types";
import { useHistory } from "react-router-dom";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { findTouchpointValue } from "../../../utils";

interface Props {
	touchpoints: C42TouchpointResponse[];
	product?: string;
	task?: string;
}

const SearchAIActions: React.FC<Props> = ({ touchpoints, task, product }) => {
	const { push } = useHistory();
	const { generateLink } = useLinkHandler();

	const onContentOpen = () => {
		if (!touchpoints.length) {
			return;
		}

		const touchpoint = touchpoints[0];

		push(generateLink({
			toc: touchpoint.documentid,
			anchor: touchpoint.anchorid,
			params: {
				product: findTouchpointValue(touchpoint.metadata, "product"),
				task: findTouchpointValue(touchpoint.metadata, "task"),
				protocol: findTouchpointValue(touchpoint.metadata, "protocol")
			}
		}));
	}

	return (
		<Box display="flex" justifyContent="center">
			<Button
				variant="contained"
				color="primary"
				disabled={!Boolean(product && task) || !touchpoints.length}
				onClick={onContentOpen}
			>
				Inhalt öffnen
			</Button>
		</Box>
	);
};

export default SearchAIActions;
