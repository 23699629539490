// Copyright (C) 2020 TANNER AG

export const FULLTEXT_ITEM_SIZE = 10;

export interface SearchHit {
	_score: number;
	id: string;
	name: string;
	document: {
		id: string;
		title: string;
		type: string;
	};
	chapters: {
		id: string;
		name: string;
	}[];
	metadata: {
		id: string;
		name: string;
		values: string[];
	}[];
	highlight: string[];
}

export interface SearchResponse {
	query: string;
	hits: {
		total: number;
		mode: "full" | "restricted";
		metadata: SearchMetadata[];
		hits: SearchHit[];
	};
}

export interface SearchMetadata {
	id: string;
	name: string;
	metadata: SearchMetadataValue[];
}

export interface SearchMetadataValue {
	name: string;
	value: string;
	count?: number;
}
