// Copyright (C) 2020 TANNER AG

import React, { useEffect } from "react";
import SideDrawer from "../../sidedrawer";
import useBreakpoint from "../../usebreakpoint";
import useShift from "@c42-ui/core/useshift";
import SideDrawerHeader from "../../sidedrawerheader";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import FilterGroup from "./filtergroup";
import FilterCheckbox from "./checkbox";
import { SearchMetadata } from "./types";
import useSearchParams from "../../usesearchparams";
import { useSearch } from "../searchprovider";

interface Props {
	metadata: SearchMetadata[];
}

const SearchFulltextFilter: React.FC<Props> = ({ metadata }) => {
	const { tabletUp } = useBreakpoint();
	const { setFilter, filter } = useSearchParams();
	const { setFulltextFilterKeys } = useSearch();
	const {
		shiftLeft: { width, active, setActive }
	} = useShift();

	const handleChange = (id: string, value: string, checked: boolean) => {
		setFilter(id, value, checked);
	};

	useEffect(() => {
		if (tabletUp) {
			setActive(true);
		}
	}, [tabletUp, setActive]);

	useEffect(() => {
		setFulltextFilterKeys(metadata.map(item => item.id));
	}, [metadata, setFulltextFilterKeys]);

	return (
		<SideDrawer
			open={active}
			setOpen={setActive}
			anchor={tabletUp ? "left" : "right"}
			width={tabletUp ? width : 350}
			appBarDisabled
			variant={tabletUp ? "persistent" : "temporary"}
		>
			{tabletUp && <Toolbar />}
			<SideDrawerHeader setOpen={tabletUp ? undefined : setActive}>
				<Typography variant="h6" color="textSecondary">
					Filter
				</Typography>
			</SideDrawerHeader>
			<Box flexGrow="1" overflow="auto">
				{metadata.map(group => (
					<FilterGroup key={group.id} label={group.name} row={!tabletUp}>
						{group.metadata.map(value => (
							<FilterCheckbox
								key={value.name}
								label={value.value}
								value={value.value}
								checked={filter[group.id]?.includes(value.value)}
								onChange={(value, checked) =>
									handleChange(group.id, value, checked)
								}
							/>
						))}
					</FilterGroup>
				))}
			</Box>
		</SideDrawer>
	);
};

export default SearchFulltextFilter;
