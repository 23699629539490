// Copyright (C) 2020 TANNER AG

import React, { ChangeEvent } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FULLTEXT_ITEM_SIZE } from "./types";
import useSearchParams from "../../usesearchparams";
import { useSearch } from "../searchprovider";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			alignItems: "center",
			margin: theme.spacing(1, 0)
		},
		pagination: {
			marginLeft: "auto"
		}
	})
);

interface Props {
	totalResults: number;
	label?: boolean;
}

const SearchFulltextPagination: React.FC<Props> = ({ totalResults, label }) => {
	const classes = useStyles();
	const { setFulltextPage, page } = useSearchParams();
	const { setMatchIndex } = useSearch();

	const handleChange = (event: ChangeEvent<unknown>, value: number) => {
		setFulltextPage(value);
		setMatchIndex(-1);
	};

	return (
		<div className={classes.root}>
			<Typography variant="subtitle2">{label && `${totalResults} Ergebnisse`}</Typography>
			<Pagination
				className={classes.pagination}
				size="small"
				onChange={handleChange}
				page={page}
				count={Math.ceil(totalResults / FULLTEXT_ITEM_SIZE)}
			/>
		</div>
	);
};

export default SearchFulltextPagination;
