// Copyright (C) 2019 TANNER AG

import $ from "jquery";
import { ScrollItem } from "./usetocbuilder";
import { DataAttribute } from "@c42-ui/core";

const DATA_KEY = "bs.tocscroller";

const Event = {
	SCROLL: `scroll.${DATA_KEY}`
};

const ClassName = {
	ACTIVE: "active",
	NAV_LINK: "nav-link"
};

interface Options {
	offset: number;
	items: ScrollItem[];
	onActivate?(item: ScrollItem): void;
	scrollToc?: boolean;
	rootId?: string;
}

export class TocScroller {
	private scrollElement: HTMLElement;
	private options: Options;
	private offsets: number[] = [];
	private targets: string[] = [];
	private activeTarget: string | null = null;
	private scrollHeight: number = 0;
	private selector: string = `#document-toc .${ClassName.NAV_LINK}`;

	public constructor(element: HTMLElement, options: Options) {
		this.scrollElement = element;
		this.options = options;

		if (options.rootId) {
			this.selector = `#${options.rootId} .${ClassName.NAV_LINK}`;
		}

		$(this.scrollElement).on(Event.SCROLL, () => this.process());

		this.refresh();
		this.process();
	}

	public update(items: ScrollItem[]) {
		this.options.items = items;
		this.refresh();
	}

	public forceProcess() {
		this.process(true);
	}

	private refresh() {
		const offsetBase = 0;

		this.offsets = [];
		this.targets = [];

		this.scrollHeight = this.getScrollHeight();

		// const rawTargets = [].slice.call(document.querySelectorAll(TocScroller.SELECTOR));
		const targets: [number, string][] = [];

		this.options.items.filter(item => item.activator).forEach(item => {
			const targetSelector = `[id="${item.activator}"]`;
			const target: HTMLElement | null = document.querySelector(targetSelector);

			if (target) {
				const targetBCR = target.getBoundingClientRect();

				if (targetBCR.width || targetBCR.height) {
					targets.push([
						($(target).offset()?.top ?? 0) + offsetBase,
						targetSelector
					]);
				}
			}
		});

		targets
			.sort((a, b) => a[0] - b[0])
			.forEach(item => {
				this.offsets.push(item[0]);
				this.targets.push(item[1]);
			});
	}

	private process(force?: boolean) {
		const scrollTop = TocScroller.getScrollTop() + this.options.offset;
		const scrollHeight = this.getScrollHeight();
		const maxScroll = this.options.offset + scrollHeight - TocScroller.getOffsetHeight();

		if (this.scrollHeight !== scrollHeight) {
			this.refresh();
		}

		if (scrollTop >= maxScroll) {
			const target = this.targets[this.targets.length - 1];

			if (this.activeTarget !== target) {
				this.activate(target);
			}

			return;
		}

		if (this.activeTarget && scrollTop < this.offsets[0] && this.offsets[0] > 0) {
			this.activeTarget = null;
			this.clear();
			return;
		}

		const offsetLength = this.offsets.length;
		for (let i = offsetLength; i--;) {
			const isActiveTarget = (this.activeTarget !== this.targets[i] || force)
				&& scrollTop >= this.offsets[i]
				&& (typeof this.offsets[i + 1] === "undefined" || scrollTop < this.offsets[i + 1]);

			if (isActiveTarget) {
				this.activate(this.targets[i]);
			}
		}
	}

	private activate(target: string) {
		this.activeTarget = target;

		this.clear();

		const item = this.options.items.find(item => `[id="${item.activator}"]` === target);

		if (item) {
			[].slice.call(document.querySelectorAll(
				item.elements
					.map(element => `${this.selector}[${DataAttribute.target}="#${element}"]`)
					.join(","))
			).forEach((node: HTMLElement) => node.classList.add(ClassName.ACTIVE));

			// Scroll to active toc item - if option is enabled.
			if (this.options.scrollToc) {
				document
					.querySelector(`${this.selector}[${DataAttribute.target}="#${item.activator}"]`)
					?.scrollIntoView(false);
			}

			// Execute activation callback function - if option is enabled.
			if (this.options.onActivate) {
				this.options.onActivate(item);
			}
		}
	}

	private getScrollHeight() {
		return this.scrollElement.scrollHeight || Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight
		)
	}

	private clear() {
		[].slice.call(document.querySelectorAll(this.selector))
			.filter((node: HTMLElement) => node.classList.contains(ClassName.ACTIVE))
			.forEach((node: HTMLElement) => node.classList.remove(ClassName.ACTIVE));
	}

	private static getScrollTop() {
		return window.pageYOffset;
	}

	private static getOffsetHeight() {
		return window.innerHeight;
	}
}
