// Copyright (C) 2020 TANNER AG

import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSearch } from "../../../searchprovider";
import PreviewNavigationItem from "./item";
import { SearchHit } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.grey[100]
		}
	})
);

const PreviewNavigation: React.FC<{ hits: SearchHit[] }> = ({ hits }) => {
	const classes = useStyles();
	const { matchIndex } = useSearch();

	if (matchIndex === undefined) {
		return null;
	}

	return (
		<Toolbar className={classes.root} disableGutters>
			<PreviewNavigationItem
				type="before"
				hit={hits[matchIndex - 1]}
				index={matchIndex - 1}
			/>
			<PreviewNavigationItem type="next" hit={hits[matchIndex + 1]} index={matchIndex + 1} />
		</Toolbar>
	);
};

export default PreviewNavigation;
