// Copyright (C) 2020 TANNER AG

import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42TouchpointResponse, SelectedAIMetadata, TaskInfo } from "./types";
import { findTouchpointValue } from "../utils";

interface GroupedTouchpoints {
	tasks: {
		id: string;
		name: string;
	}[];
	products: {
		id: string;
		name: string;
	}[];
}

const filterMetadata = (
	touchpoints: C42TouchpointResponse[],
	intentTasks: string[],
	selectedMetadata?: SelectedAIMetadata
): GroupedTouchpoints => {
	const tasks: { id: string; name: string }[] = [];
	const products: { id: string; name: string }[] = [];

	touchpoints.forEach(touchpoint => {
		const product = findTouchpointValue(touchpoint.metadata, "product");
		if (product && !products.find(currentProduct => currentProduct.id === product)) {
			products.push({ id: product, name: product });
		}

		const task = findTouchpointValue(touchpoint.metadata, "task");
		if (
			task &&
			(!intentTasks.length || intentTasks.includes(task)) &&
			!tasks.find(currentTask => currentTask.id === task) &&
			(!selectedMetadata?.product || product === selectedMetadata?.product)
		) {
			tasks.push({ id: task, name: task });
		}
	});

	return {
		tasks,
		products
	};
};

const filterTouchpoints = (
	touchpoints: C42TouchpointResponse[],
	selectedMetadata?: SelectedAIMetadata
) =>
	touchpoints.filter(touchpoint => {
		const matchProduct =
			!selectedMetadata?.product ||
			selectedMetadata?.product === findTouchpointValue(touchpoint.metadata, "product");
		const matchTask =
			!selectedMetadata?.task ||
			selectedMetadata?.task === findTouchpointValue(touchpoint.metadata, "task");
		const matchProtocol =
			!selectedMetadata?.protocol ||
			selectedMetadata?.task !== "product_connect_fieldbus" ||
			selectedMetadata?.protocol === findTouchpointValue(touchpoint.metadata, "protocol");

		return matchProduct && matchTask && matchProtocol;
	});

interface TouchPointsReturn {
	metadata: GroupedTouchpoints;
	touchpoints: C42TouchpointResponse[];
}

const useTouchPoints = (
	intent?: string,
	selectedMetadata?: SelectedAIMetadata,
	tasks?: TaskInfo[]
): TouchPointsReturn => {
	const { data: touchpoints } = useServiceFetch<C42TouchpointResponse[]>({
		url: "/user/touchpoints/document/content"
	});

	if (!touchpoints) {
		return {
			metadata: { products: [], tasks: [] },
			touchpoints: []
		};
	}

	const intentTasks = intent
		? tasks?.filter(task => task.intent === intent).map(task => task.id)
		: [];

	return {
		metadata: filterMetadata(touchpoints, intentTasks || [], selectedMetadata),
		touchpoints: filterTouchpoints(touchpoints, selectedMetadata)
	};
};

export default useTouchPoints;
