// Copyright (C) 2020 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { textFontFamily } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			fontFamily: textFontFamily,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.primary.main,
			padding: theme.spacing(1, 0)
		}
	}),
);

const PaperHeader: React.FC = ({ children }) => {
	const classes = useStyles();

	return (
		<Typography className={classes.title} color="textPrimary">
			{children}
		</Typography>
	);
}

export default PaperHeader;
