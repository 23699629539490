// Copyright (C) 2020 TANNER AG

import React, { useEffect } from "react";
import useFulltextSearch from "./usefulltextsearch";
import useSearchParams from "../../usesearchparams";
import SearchFulltextItem from "./searchfulltextitem";
import SearchFulltextFilter from "./searchfulltextfilter";
import SearchFulltextPagination from "./searchfulltextpagination";
import { SearchContainer } from "../utils";
import SearchFulltextPreview from "./preview";
import SearchProgress from "./searchprogress";
import useShift from "@c42-ui/core/useshift";
import { useSearch } from "../searchprovider";

const SearchFulltext = () => {
	const { query, page } = useSearchParams();
	const { hits, loading } = useFulltextSearch(query, page);
	const { setMatchIndex } = useSearch();
	const { shiftRight: { setActive } } = useShift();

	useEffect(() => {
		return () => {
			setActive(false);
			setMatchIndex(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <SearchProgress/>;
	}

	return (
		<>
			<SearchContainer>
				<SearchFulltextPagination totalResults={hits?.total || 0} label/>
				{hits?.hits.map((hit, index) => (
					<SearchFulltextItem
						key={hit.id}
						index={index}
						item={hit}
					/>
				))}
				<SearchFulltextPagination totalResults={hits?.total || 0}/>
			</SearchContainer>
			<SearchFulltextFilter metadata={hits?.metadata || []}/>
			<SearchFulltextPreview hits={hits?.hits || []}/>
		</>
	);
};

export default SearchFulltext;
