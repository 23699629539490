// Copyright (C) 2020 TANNER AG

import React from "react";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { MetaInfo } from "./types";
import Tab from "@material-ui/core/Tab";
import { ActionTabPanel } from "@c42-ui/core/actiontabs";
import useHTMLParser from "@c42-ui/core/usehtmlparser";
import Box from "@material-ui/core/Box";
import useDocumentTouchpoints from "./document/usedocumenttouchpoints";
import DocumentTouchpointsDrawer from "./document/drawer/documenttouchpointstab";
import { DatabaseID } from "../const";

interface Props {
	metaInfoId: string;
	documentId: string;
}

const TabContent: React.FC<Props> = ({ metaInfoId, documentId }) => {
	const { parseHTML } = useHTMLParser();
	const { data } = useServiceFetch<string>({
		url: `/user/documents/${DatabaseID.Content}/${documentId}/metainfos/${metaInfoId}`,
		isText: true
	});

	return (
		<Box id="main-content" mx={3}>
			{parseHTML(data || "")}
		</Box>
	);
};

interface InfoTabs {
	indexes: string[];
	titles: string[];
	tabs: JSX.Element[];
	contents: JSX.Element[];
}

const useMetaInfoTabs = (documentId: string, activeIndex: number) => {
	const touchpoints = useDocumentTouchpoints();
	const { data } = useServiceFetch<MetaInfo[]>({
		url: `/user/documents/${DatabaseID.Content}/${documentId}/metainfos`
	});

	const infoTabs: InfoTabs = {
		indexes: [],
		titles: [],
		tabs: [],
		contents: []
	};

	if (touchpoints && touchpoints.length && touchpoints[0].subtouchpoints && touchpoints[0].subtouchpoints.length) {
		const touchpoint = touchpoints[0];

		infoTabs.indexes.push("touchpoints");
		infoTabs.titles.push("Zusätzliche Info");
		infoTabs.tabs.push(
			<Tab
				key="touchpoints"
				value={infoTabs.tabs.length}
				label="Zusätzliche Info"
			/>
		);
		infoTabs.contents.push(
			<ActionTabPanel key="touchpoints" value={activeIndex} index={infoTabs.contents.length}>
				<Box id="main-content" mx={3}>
					<DocumentTouchpointsDrawer touchpoint={touchpoint}/>
				</Box>
			</ActionTabPanel>
		);
	}

	data?.forEach((tab: MetaInfo, index: number) => {
		infoTabs.indexes.push(tab.id);
		infoTabs.titles.push(tab.label);
		infoTabs.tabs.push(
			<Tab
				key={tab.id}
				value={infoTabs.tabs.length}
				label={tab.label}
			/>
		);
		infoTabs.contents.push(
			<ActionTabPanel key={tab.id} value={activeIndex} index={infoTabs.contents.length}>
				<TabContent
					metaInfoId={tab.id}
					documentId={documentId}
				/>
			</ActionTabPanel>
		);
	});

	return infoTabs;
};

export default useMetaInfoTabs;
