// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";
import DocumentContext from "./documentcontext";
import { TocItem } from "@c42-ui/core/types";

const DocumentProvider: React.FC = ({ children }) => {
	const [document, setDocument] = useState<string>();
	const [tocItems, setTocItems] = useState<TocItem[]>();
	const inDocument = Boolean(document);

	return (
		<DocumentContext.Provider value={{ document, setTocItems, tocItems, setDocument, inDocument }}>
			{children}
		</DocumentContext.Provider>
	);
};

export default DocumentProvider;
