// Copyright (C) 2020 TANNER AG

import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { textFontFamily } from "../../../theme";
import { MEDIA_SERVER_URL } from "../../../const";
import useProducts from "../../useproducts";
import { useHistory } from "react-router-dom";
import { parse } from "query-string";
import useShift from "@c42-ui/core/useshift";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			alignItems: "center",
			cursor: "pointer"
		},
		imageWrapper: {
			height: 35,
		},
		image: {
			height: "100%"
		},
		title: {
			fontFamily: textFontFamily,
			fontSize: "0.875rem",
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.primary.main
		},
		subTitle: {
			fontFamily: textFontFamily,
			fontSize: "0.875rem",
		}
	})
);

const Product: React.FC = () => {
	const classes = useStyles();
	const { products } = useProducts();
	const { location: { search } } = useHistory();
	const { product: productId } = parse(search);
	const { shiftRight: { active, setActive } } = useShift();

	if (!productId || active) {
		return null;
	}

	const product = products.find(product => product.id === productId);
	if (!product) {
		return null;
	}

	return (
		<Box display="flex" className={classes.root} onClick={() => setActive(true)} ml={3}>
			<Box mr={1}>
				<Typography className={classes.title}>{product.name}</Typography>
				<Typography className={classes.subTitle} color="textSecondary" align="right">{product.description}</Typography>
			</Box>
			<div className={classes.imageWrapper}>
				<img
					className={classes.image}
					src={`${MEDIA_SERVER_URL}/${product.id}_thumbnail.png`}
					alt={product.name}
				/>
			</div>
		</Box>
	);
};

export default Product;
