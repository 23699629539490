// Copyright (C) 2020 TANNER AG

import React, { useLayoutEffect } from "react";
import { LinkTarget } from "@c42-ui/core/types";
import useHTMLParser from "@c42-ui/core/usehtmlparser";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { PREVIEW_ID } from "../searchfulltextpreview";
import useDesign from "../../../../document/content/design";
import { DatabaseID } from "../../../../../const";
import { SearchHit } from "../../types";
import SearchProgress from "../../searchprogress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Box from "@material-ui/core/Box";

type Props = {
	hit: SearchHit;
	target?: LinkTarget;
	query: string;
};

const PreviewContent: React.FC<Props> = ({ target, query, hit }) => {
	useDesign();
	const { parseHTML } = useHTMLParser();
	const { data, loading, error } = useServiceFetch<string>({
		url: `/user/chapters/{databaseId}/{documentId}/{chapterId}`,
		params: {
			databaseId: DatabaseID.Content,
			documentId: hit.document.id,
			chapterId: hit.id
		},
		isText: true,
		isLazy: !Boolean(target)
	});

	useLayoutEffect(() => {
		const previewRootEl = document.getElementById(PREVIEW_ID);

		if (previewRootEl) {
			previewRootEl.scrollTop = 0;
		}
	}, [data, query]);

	if (loading) {
		return <SearchProgress />;
	}

	if (error) {
		return (
			<Box mt={4}>
				<Alert severity="error">
					<AlertTitle>Vorschau konnte nicht geladen werden</AlertTitle>
					{error}
				</Alert>
			</Box>
		);
	}

	return <>{parseHTML(data || "")}</>;
};

export default PreviewContent;
