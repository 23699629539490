// Copyright (C) 2020 TANNER AG

import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import PaperHeader from "../../paperheader";
import List from "@material-ui/core/List";
import { SearchItem } from "./searchai";
import { ProductInfo } from "../../types";

interface Props {
	productOptions: { id: string; name: string }[];
	products: ProductInfo[];
	selected?: string;
	onClick(id?: string): void;
}

const SearchAIProducts: React.FC<Props> = ({ productOptions, products, selected, onClick }) => {
	return (
		<List
			disablePadding
			dense
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					<PaperHeader>Wählen Sie ihr Produkt</PaperHeader>
				</ListSubheader>
			}
		>
			{productOptions.map(task => (
				<SearchItem
					key={task.id}
					selected={selected === task.id}
					onClick={() => onClick(task.id)}
					label={
						products.find(productInfo => productInfo.id === task.id)?.name || task.id
					}
				/>
			))}
		</List>
	);
};

export default SearchAIProducts;
