// Copyright (C) 2019 TANNER AG

import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { Overrides } from "@material-ui/core/styles/overrides";

export const textFontFamily = ["Lato", "Calibri", "Open Sans", "Roboto", "Droid Sans", "Segoe UI"].join(",");

const overrides: Overrides = {
	MuiTabs: {
		indicator: {
			height: 4
		}
	},
	MuiPaper: {
		rounded: {
			borderRadius: 2
		}
	},
	MuiButton: {
		root: {
			textTransform: "capitalize"
		},
		contained: {
			borderRadius: 2
		},
		outlined: {
			borderRadius: 2
		}
	},
	MuiChip: {
		root: {
			borderRadius: 4
		}
	}
};

export const theme: ThemeOptions = {
	overrides,
	palette: {
		primary: {
			contrastText: "#fff",
			dark: "#005EA9",
			light: "#33C4FF",
			main: "#0091dc"
		},
		secondary: {
			contrastText: "#fff",
			dark: "#3d4347",
			light: "#00b9b9",
			main: "#70767b"
		},
		error: {
			contrastText: "#fff",
			dark: "#006f6f",
			light: "#e57373",
			main: "#f81411"
		},
		text: {
			disabled: "rgba(0, 0, 0, 0.38)",
			hint: "rgba(0, 0, 0, 0.38)",
			primary: "rgba(0, 0, 0, 0.87)",
			secondary: "rgba(0, 0, 0, 0.54)"
		},
		background: {
			default: "#fff",
			paper: "#fff"
		},
		grey: {
			50: "#fafafa",
			100: "#f8f8f8",
			200: "#f1f1f1",
			300: "#e0e0e0",
			400: "#c9c9c9",
			500: "#b7b7b7",
			600: "#929292",
			700: "#686868",
			800: "#565656",
			900: "#3f3f3f",
			A100: "#d5d5d5",
			A200: "#aaaaaa",
			A400: "#303030",
			A700: "#616161"
		}
	}
};

export const darkTheme: ThemeOptions = {
	palette: {
		primary: {
			contrastText: "#fff",
			dark: "#005EA9",
			light: "#33C4FF",
			main: "#0091dc"
		},
		secondary: {
			contrastText: "#fff",
			dark: "#3d4347",
			light: "#00b9b9",
			main: "#70767b"
		},
		error: {
			contrastText: "#fff",
			dark: "#d32f2f",
			light: "#e57373",
			main: "#f81411"
		},
		text: {
			disabled: "rgba(255, 255, 255, 0.5)",
			hint: "rgba(255, 255, 255, 0.5)",
			primary: "#fff",
			secondary: "rgba(255, 255, 255, 0.7)"
		},
		background: {
			default: "#1b1b1b",
			paper: "#424242"
		},
		grey: {
			900: "#fafafa",
			800: "#f8f8f8",
			700: "#f1f1f1",
			600: "#e0e0e0",
			500: "#c9c9c9",
			400: "#b7b7b7",
			300: "#929292",
			200: "#686868",
			100: "#565656",
			50: "#3f3f3f",
			A100: "#d5d5d5",
			A200: "#aaaaaa",
			A400: "#303030",
			A700: "#616161"
		}
	},
	overrides
};
