// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";
import MaterialAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AuthToolbar from "@c42-ui/core/authtoolbar";
import { Link } from "react-router-dom";
import Navigation from "../navigation";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useC42 } from "@c42-ui/core";
import clsx from "clsx";
import Omnibox from "../omnibox";
import useBreakpoint from "../usebreakpoint";
import Typography from "@material-ui/core/Typography";
import useDocument from "../document/provider";
import { textFontFamily } from "../../theme";
import AppBarAction from "@c42-ui/core/appbaraction";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.secondary,
			zIndex: theme.zIndex.drawer + 1
		},
		noShadow: {
			boxShadow: "none"
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		content: {
			display: "flex",
			flex: "1 1 auto",
			alignItems: "center"
		},
		logoLink: {
			display: "block",
			height: 25,
			marginRight: theme.spacing(3)
		},
		logo: {
			height: "100%"
		},
		title: {
			fontSize: 21,
			lineHeight: "1.6",
			fontFamily: textFontFamily,
			fontWeight: theme.typography.fontWeightBold
		},
		search: {
			maxWidth: 500,
			width: "100%",
			position: "absolute",
			left: "50%",
			top: "50%",
			transform: "translate(-50%, -50%)"
		}
	})
);

const AppBar = () => {
	const classes = useStyles();
	const { tabletUp } = useBreakpoint();
	const { setup: { app: { name, basename }, services: { domain } } } = useC42();
	const [navOpen, setNavOpen] = useState(false);
	const { inDocument } = useDocument();

	return (
		<>
			<MaterialAppBar className={clsx(classes.appBar, { [classes.noShadow]: inDocument })}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						onClick={() => setNavOpen(true)}
						data-cy="menu-btn"
					>
						<MenuIcon/>
					</IconButton>
					<div className={classes.content}>
						<Link to="/" className={classes.logoLink}>
							<img
								alt={`${name} logo`}
								src={`${basename}/logo.svg`}
								className={classes.logo}
							/>
						</Link>
						{tabletUp && (
							<Typography className={classes.title} variant="h6" color="inherit">
								Infoportal
							</Typography>
						)}
					</div>
					{!tabletUp && (
						<AppBarAction
							title="Search"
							component={Link}
							to="/search"
							color="inherit"
						>
							<SearchIcon/>
						</AppBarAction>
					)}
					<AuthToolbar
						edge="end"
						onAdminClick={() => window.open(`${domain}/c42-core/admin`, "_blank")}
					/>
					{tabletUp && (
						<div className={classes.search}>
							<Omnibox global/>
						</div>
					)}
				</Toolbar>
			</MaterialAppBar>
			<Toolbar id="back-to-top-anchor"/>
			<Navigation open={navOpen} setOpen={setNavOpen}/>
		</>
	);
};

export default AppBar;
