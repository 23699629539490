// Copyright (C) 2020 TANNER AG

import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { StateProvider } from "react-zoom-pan-pinch/dist/store/StateContext";
import ImageDialogToolbar from "./imagedialogtoolbar";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
	createStyles({
		imageWrapper: {
			overflow: "hidden",
			alignSelf: "center",
			padding: theme.spacing(1),
			height: "calc(100vh - 130px)",
			width: "100%",

			"& > div": {
				width: "100% !important",
				height: "100% !important"
			}
		},
		image: {
			maxWidth: "100%",
			maxHeight: "calc(100vh - 130px)",

			[theme.breakpoints.down("sm")]: {
				maxHeight: "calc(100vh - 56px)"
			}
		}
	})
);

const ImagePanZoom: React.FC<{ src: string }> = ({ src }) => {
	const classes = useStyles();

	return (
		<TransformWrapper wheel={{ step: 12 }}>
			{({ zoomIn, zoomOut, resetTransform }: StateProvider) => (
				<>
					<ImageDialogToolbar
						zoomIn={zoomIn}
						zoomOut={zoomOut}
						resetTransform={resetTransform}
					/>
					<div className={classes.imageWrapper}>
						<TransformComponent>
							<img
								src={src}
								alt="fullscreen"
								className={classes.image}
							/>
						</TransformComponent>
					</div>
				</>
			)}
		</TransformWrapper>
	);
};

export default ImagePanZoom;
