// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";

interface ImageContextProps {
	activeSource?: string;
	setSource(source?: string): void;
}

const ImageContext = React.createContext<ImageContextProps>({
	setSource: () => null
});

export const useImage = () => React.useContext(ImageContext);

const ImageProvider: React.FC = ({ children }) => {
	const [activeSource, setSource] = useState<string>();

	return (
		<ImageContext.Provider value={{ activeSource, setSource }}>
			{children}
		</ImageContext.Provider>
	);
};

export default ImageProvider;
