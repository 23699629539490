// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";
import useDocument from "../../document/provider";
import DocumentToc from "../../document/toc";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { scrollbarSmall } from "@c42-ui/core/utils/mixins";
import useBreakpoint from "../../usebreakpoint";
import DocumentTocToolbar from "../../document/toc/toolbar";

interface Props {
	onTargetClick(): void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "calc(100% - 64px - 48px)",
			overflowY: "hidden"
		},
		paperScroll: {
			overflow: "auto",
			height: "calc(100% - 34px)",
			...scrollbarSmall
		},
	})
);

const NavigationDocumentToc: React.FC<Props> = ({ onTargetClick }) => {
	const classes = useStyles();
	const { tocItems, inDocument } = useDocument();
	const { tabletUp } = useBreakpoint();
	const [query, setQuery] = useState("");

	if (tabletUp || !inDocument || !tocItems) {
		return null;
	}

	return (
		<div className={classes.root}>
			<DocumentTocToolbar
				query={query}
				setQuery={setQuery}
			/>
			<div className={classes.paperScroll}>
				<DocumentToc
					id="mobileToc"
					tocItems={tocItems}
					query={query}
					onTargetClick={onTargetClick}
				/>
			</div>
		</div>
	);
};

export default NavigationDocumentToc;
