// Copyright (C) 2020 TANNER AG

import useFetch from "@c42-ui/core/utils/usefetch";
import { HttpHeader } from "@c42-ui/core/consts";
import useAuth from "@c42-ui/core/useauth";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { AISearchResponse, LuisResponse, SpellcheckQuery, SpellcheckResponse } from "./types";
import { ProductInfo } from "../../types";
import { PROTOCOL_ENTITIES } from "../../../const";

const getIntent = (data: LuisResponse): [string, number | undefined] => {
	if (!data) {
		return ["", undefined];
	}

	const topIntent = data?.prediction.topIntent;
	const topScore = data?.prediction.intents[topIntent]?.score || 0;

	if (topIntent === "None" || topScore < 0.7) {
		return ["", undefined];
	}

	return [topIntent.toLowerCase(), topScore];
};

const getSpellCheck = (
	query: string,
	productNames: string,
	spellcheck?: SpellcheckResponse
): SpellcheckQuery[] | undefined => {
	if (!spellcheck || !spellcheck.flaggedTokens?.length) {
		return;
	}

	const fixedQuery = query.split(" ").map(query => {
		const flagToken = spellcheck.flaggedTokens.find(flagToken => flagToken.token === query);

		if (flagToken && !productNames.includes(query.toLowerCase())) {
			return { suggestion: true, query: flagToken.suggestions[0].suggestion };
		}

		return { suggestion: false, query };
	});

	// If no suggestion available, the fixed query need not be displayed.
	if (fixedQuery.every(query => !query.suggestion)) {
		return;
	}

	return fixedQuery;
};

interface UseLuis {
	intent?: string;
	score?: number;
	product?: string;
	protocol?: string;
	fixedQueries?: SpellcheckQuery[];
	loading: boolean;
}

const useLuis = (query: string, products: ProductInfo[]): UseLuis => {
	const {
		service: { domain }
	} = useLinkHandler();
	const { getBearer } = useAuth();
	const { data, loading } = useFetch<AISearchResponse>({
		url: `${domain}/c42-core/api/v1/aisearch?q=${query}`,
		headers: { [HttpHeader.Authorization]: getBearer() },
		isLazy: !query
	});

	if (!data) {
		return { loading };
	}

	const splitedQuery = query.trim().toLowerCase().split(" ");
	const [intent, score] = getIntent(data.luis);
	const fixedQueries = getSpellCheck(
		query,
		products
			.map(p => p.name)
			.join(" ")
			.toLowerCase(),
		data.spellcheck
	);

	return {
		intent,
		score,
		fixedQueries,
		loading,
		product: products.find(product =>
			splitedQuery.some(q => product.name.toLowerCase().includes(q))
		)?.id,
		protocol: PROTOCOL_ENTITIES.find(protocol =>
			splitedQuery.some(q => protocol.toLowerCase().includes(q))
		)
	};
};

export default useLuis;
