// Copyright (C) 2020 TANNER AG

import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useSearch } from "../../../../searchprovider";
import { textFontFamily } from "../../../../../../theme";
import { SearchHit } from "../../../types";

interface Props {
	type: "before" | "next";
	hit?: SearchHit;
	index: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			flexGrow: 1,
			overflow: "hidden"
		},
		item: {
			width: "50%",
			height: "100%",
			display: "flex",
		},
		icon: {
			width: 40
		},
		itemNext: {
			textAlign: "right",
			flexDirection: "row-reverse",
			paddingLeft: theme.spacing(2)
		},
		itemBefore: {
			textAlign: "left",
			borderRight: `1px solid ${theme.palette.grey[300]}`,
			paddingRight: theme.spacing(2)
		},
		chapterTitle: {
			fontFamily: textFontFamily,
			fontSize: "1rem",
			fontWeight: theme.typography.fontWeightRegular
		}
	}),
);

const DocumentTitle: React.FC = ({ children }) => (
	<Typography
		color="textSecondary"
		variant="caption"
		noWrap
	>
		{children}
	</Typography>
);

const ChapterTitle: React.FC = ({ children }) => {
	const classes = useStyles();
	return (
		<Typography
			className={classes.chapterTitle}
			variant="subtitle2"
			noWrap
		>
			{children}
		</Typography>
	);
};

const PreviewNavigationItem: React.FC<Props> = ({ type, hit, index }) => {
	const classes = useStyles();
	const { setMatchIndex } = useSearch();

	return (
		<ButtonBase
			onClick={() => setMatchIndex(index)}
			disabled={!Boolean(hit)}
			className={clsx(classes.item, {
				[classes.itemBefore]: type === "before",
				[classes.itemNext]: type === "next"
			})}
		>
			{document && (
				<>
					{(type === "before" ? (
						<NavigateBeforeIcon className={classes.icon}/>
					) : (
						<NavigateNextIcon className={classes.icon}/>
					))}
					<div className={classes.content}>
						<DocumentTitle>{hit?.document.title}</DocumentTitle>
						<ChapterTitle>{hit?.name}</ChapterTitle>
					</div>
				</>
			)}
		</ButtonBase>
	);
};

export default PreviewNavigationItem;
