// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";

type SearchContextProps = {
	fulltextFilterKeys: string[];
	matchIndex?: number;
	setFulltextFilterKeys(keys: string[]): void;
	setMatchIndex(index?: number): void;
};

const SearchContext = React.createContext<SearchContextProps>({
	fulltextFilterKeys: [],
	setFulltextFilterKeys: () => null,
	setMatchIndex: () => null
});

export const useSearch = () => React.useContext(SearchContext);

const SearchProvider: React.FC = ({ children }) => {
	const [fulltextFilterKeys, setFulltextFilterKeys] = useState<string[]>([]);
	const [matchIndex, setMatchIndex] = useState<number>();

	return (
		<SearchContext.Provider value={{ fulltextFilterKeys, setFulltextFilterKeys, matchIndex, setMatchIndex }}>
			{children}
		</SearchContext.Provider>
	);
};

export default SearchProvider;
