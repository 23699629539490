// Copyright (C) 2020 TANNER AG

import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@c42-ui/core/container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { textFontFamily } from "../../../theme";
import { SearchMode } from "../../../const";
import useSearchParams from "../../usesearchparams";
import { SearchContainer } from "../utils";
import SearchTabbarFilterAction from "./searchtabbarfilteraction";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.grey[100],
			zIndex: -1
		},
		title: {
			marginRight: theme.spacing(1)
		},
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: 0
		},
		tab: {
			fontFamily: textFontFamily,
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "1rem",
			textTransform: "none",
			minWidth: 120
		},
		tabSelected: {
			color: theme.palette.text.primary
		},
		filterIcon: {
			marginLeft: "auto"
		}
	})
);

const SearchTabbar = () => {
	const classes = useStyles();
	const { setSearchMode, searchMode } = useSearchParams();

	return (
		<Toolbar className={classes.root} variant="dense">
			<Container className={classes.container}>
				<SearchContainer display="flex" alignItems="center">
					<Tabs
						value={searchMode}
						onChange={(_: unknown, mode: SearchMode) => setSearchMode(mode)}
						indicatorColor="primary"
					>
						<Tab
							value={SearchMode.AI}
							label="Aufgaben"
							disabled={!document}
							className={classes.tab}
							classes={{ selected: classes.tabSelected }}
						/>
						<Tab
							value={SearchMode.Fulltext}
							label="Volltext"
							className={classes.tab}
							classes={{ selected: classes.tabSelected }}
						/>
					</Tabs>
					<SearchTabbarFilterAction/>
				</SearchContainer>
			</Container>
		</Toolbar>
	);
};

export default SearchTabbar;
