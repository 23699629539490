// Copyright (C) 2020 TANNER AG

import { TocItem } from "@c42-ui/core/types";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { DatabaseID } from "../../const";

export const readTocItems = (items: TocItem[], fragments: string[], anchors: string[]) => {
	items.forEach(item => {
		if (item.target.content && !fragments.includes(item.target.content)) {
			fragments.push(item.target.content);
		}

		if (item.target.anchor && !anchors.includes(item.target.anchor)) {
			anchors.push(item.target.anchor);
		}

		readTocItems(item.nodes, fragments, anchors);
	})
};

interface UseDocumentTocFetch {
	loading: boolean;
	tocItems: TocItem[];
}

const useDocumentTocFetch = (documentId: string): UseDocumentTocFetch => {
	const { data: tocItems, loading } = useServiceFetch<TocItem[]>({
		url: `/user/documents/${DatabaseID.Content}/${documentId}/toc`,
	});

	return {
		loading,
		tocItems: tocItems || []
	};
};

export default useDocumentTocFetch;
