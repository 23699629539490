// Copyright (C) 2020 TANNER AG

import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() =>
	createStyles({
		anchorLeft: {
			flexDirection: "row-reverse"
		}
	})
);

interface SideDrawerHeaderProps {
	setOpen?(open: boolean): void;
	anchor?: "left" | "right"
}

const SideDrawerHeader: React.FC<SideDrawerHeaderProps> = ({ setOpen, children, anchor = "right" }) => {
	const classes = useStyles();
	return (
		<Toolbar className={clsx({ [classes.anchorLeft]: anchor === "left" })}>
			<Box flexGrow={1}>
				{children}
			</Box>
			{setOpen && (
				<IconButton
					edge={(anchor === "left") ? "start": "end"}
					color="inherit"
					onClick={() => setOpen(false)}
				>
					<CloseIcon/>
				</IconButton>
			)}
		</Toolbar>
	);
};

export default SideDrawerHeader;
