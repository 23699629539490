// Copyright (C) 2020 TANNER AG

import { createStyles, makeStyles, Theme, fade } from "@material-ui/core/styles";
import { textFontFamily } from "../../theme";

const useTocLayout = makeStyles((theme: Theme) =>
	createStyles({
		"@global": {
			".tocList": {
				padding: 0,
				margin: 0,

				"& .tocListItem": {
					backgroundColor: fade(theme.palette.primary.main, 0.05),
				},

				"& .tocList": {
					paddingLeft: theme.spacing(3),

					"& .tocListItem": {
						backgroundColor: theme.palette.grey["100"],
					},

					"& .tocList .tocListItem": {
						backgroundColor: "transparent"
					}
				}
			},
			".rootTocList > .tocListItemContainer": {
				borderBottom: `1px solid ${fade(theme.palette.primary.main, 0.3)}`
			},
			".tocListItemContainer": {
				position: "relative",
				margin: 0,
				padding: 0,
				listStyle: "none"
			},
			".tocListItem": {
				border: 0,
				borderRight: "4px solid transparent",
				cursor: "pointer",
				margin: 0,
				color: theme.palette.text.primary,
				outline: 0,
				userSelect: "none",
				verticalAlign: "middle",
				display: "flex",
				position: "relative",
				boxSizing: "border-box",
				textAlign: "left",
				alignItems: "center",
				justifyContent: "flex-start",
				textDecoration: "none",
				padding: theme.spacing(2, 1.5),
				paddingTop: theme.spacing(0.5),
				paddingBottom: theme.spacing(0.5),
				paddingRight: theme.spacing(6),
				transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

				"&:hover": {
					textDecoration: "none",
					backgroundColor: `${theme.palette.grey["300"]} !important`
				},

				"&.active": {
					backgroundColor: `${theme.palette.grey["300"]} !important`,
					borderRightColor: `${theme.palette.primary.main} !important`
				},

				"& ~ .tocListItemActionContainer": {
					"& .tocListItemActionOpen": {
						display: "none"
					},
					"& .tocListItemActionClose": {
						display: "block"
					}
				},

				"&.open, &.active": {
					"& + ul": {
						display: "block",
					},

					"& ~ .tocListItemActionContainer": {
						"& .tocListItemActionOpen": {
							display: "block"
						},
						"& .tocListItemActionClose": {
							display: "none"
						}
					}
				},

				"& + ul": {
					display: "none",
				}
			},
			".tocListItemTextContainer": {
				flexBasis: "100%",
				flex: "1 1 auto",
				minWidth: 0,
				marginTop: theme.spacing(0.5),
				marginBottom: theme.spacing(0.5),
				...theme.typography.subtitle2,
				fontFamily: textFontFamily,

				"& .tocListItemText": {
					display: "flex"
				},

				"& .tocListItemNumber": {
					marginRight: theme.spacing(1),
					color: theme.palette.text.secondary,
					fontWeight: theme.typography.fontWeightBold
				},
				"& .tocListItemLabel": {
					fontWeight: theme.typography.fontWeightRegular
				}
			},
			".tocListItemActionContainer": {
				position: "absolute",
				top: 0,
				right: 0,

				"& .tocListItemAction": {
					width: 37,
					height: 37,
					borderRadius: 0
				},
			}
		}
	})
);

export default useTocLayout;
