// Copyright (C) 2019 TANNER AG

import React from "react";
import Setup from "./setup";
import Appbar from "./components/appbar";
import { Switch, Route } from "react-router-dom";
import useAuth from "@c42-ui/core/useauth";
import SignIn from "@c42-ui/core/signin";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Document from "./components/document";
import Product from "./components/product";
import ShiftProvider from "@c42-ui/core/shiftprovider";
import Home from "./components/home";
import SearchProvider from "@c42-ui/core/searchprovider";
import Search from "./components/search";
import { DocumentProvider } from "./components/document/provider";

import "./styles/basic.scss"

const App: React.FC = () => {
	const { user } = useAuth();
	const { routes: { document, product } } = useLinkHandler();

	if (!user) {
		return <SignIn/>;
	}

	return (
		<DocumentProvider>
			<SearchProvider>
				<ShiftProvider>
					<Route path={["/", document, product, `${product}/*`, "/admin", "/admin/*"]} exact>
						<Appbar/>
					</Route>
					<Switch>
						<Route path="/" exact>
							<Home/>
						</Route>
						<Route path={document}>
							<Document/>
						</Route>
						<Route path={product}>
							<Product/>
						</Route>
						<Route path="/search">
							<Search/>
						</Route>
					</Switch>
				</ShiftProvider>
			</SearchProvider>
		</DocumentProvider>
	);
};

const AppSetup: React.FC = () => (
	<Setup>
		<App/>
	</Setup>
);

export default AppSetup;
