// Copyright (C) 2020 TANNER AG

import React, { useEffect } from "react";
import { useSearch } from "../../searchprovider";
import useShift from "@c42-ui/core/useshift";
import useSearchParams from "../../../usesearchparams";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import SideDrawer from "../../../sidedrawer";
import SideDrawerHeader from "../../../sidedrawerheader";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { useHistory } from "react-router-dom";
import { LinkTarget } from "@c42-ui/core/types";
import { scrollbarSmall } from "@c42-ui/core/utils/mixins";
import useBreakpoint from "../../../usebreakpoint";
import PreviewNavigation from "./navigation";
import { textFontFamily } from "../../../../theme";
import { SearchHit } from "../types";
import PreviewContent from "./content";

export const PREVIEW_ID = "preview";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			overflow: "hidden",
			height: "100%"
		},
		scrollWrapper: {
			height: "100%",
			overflow: "scroll",
			paddingTop: 0,
			padding: theme.spacing(3),
			...scrollbarSmall
		},
		chapterTitle: {
			fontFamily: textFontFamily,
			fontWeight: theme.typography.fontWeightRegular,
			lineHeight: 1
		},
		openBtn: {
			marginRight: theme.spacing(2)
		}
	}),
);

const SearchFulltextPreview: React.FC<{ hits: SearchHit[] }> = ({ hits }) => {
	const classes = useStyles();
	const { matchIndex, setMatchIndex } = useSearch();
	const { tabletUp } = useBreakpoint();
	const { query } = useSearchParams();
	const { shiftRight: { active, setActive, width } } = useShift();
	const { generateLink } = useLinkHandler();
	const { push } = useHistory();

	const match: SearchHit | undefined = (matchIndex !== undefined) ? hits[matchIndex] : undefined;

	useEffect(() => {
		setActive(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		if (match) {
			setActive(true);
		} else {
			setActive(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match]);

	if (!match) {
		return null;
	}

	const target: LinkTarget = {
		toc: match.document.id,
		anchor: match.id
	};

	const handleClick = () => push(generateLink(target));

	const handleActive = (active: boolean) => {
		if (!active) {
			setMatchIndex(undefined);
		}
	};

	return (
		<SideDrawer
			width={tabletUp ? width : "90%"}
			open={active}
			setOpen={handleActive}
			anchor="right"
			variant={tabletUp ? "persistent" : "temporary"}
			appBarDisabled
		>
			{tabletUp && (
				<>
					<Toolbar/>
					<Toolbar variant="dense"/>
				</>
			)}
			<SideDrawerHeader setOpen={handleActive}>
				<Box display="flex" alignItems="center">
					<Box flexGrow={1}>
						<Typography
							color="textSecondary"
							variant="caption"
						>
							{match.name}
						</Typography>
						<Typography
							variant="h6"
							color="textSecondary"
							className={classes.chapterTitle}
						>
							Preview {(matchIndex || 0) + 1}/{hits.length}
						</Typography>
					</Box>
					<Button
						variant="contained"
						color="primary"
						size="small"
						className={classes.openBtn}
						onClick={handleClick}
					>
						Open
					</Button>
				</Box>
			</SideDrawerHeader>
			<PreviewNavigation hits={hits}/>
			<div className={clsx("content", classes.root)}>
				<div id={PREVIEW_ID} className={classes.scrollWrapper}>
					<PreviewContent
						target={target}
						query={query}
						hit={match}
					/>
				</div>
			</div>
		</SideDrawer>
	);
};

export default SearchFulltextPreview;
