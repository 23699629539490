// Copyright (C) 2020 TANNER AG

import React from "react";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42MetadataResponse } from "../../types";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import Link from "@c42-ui/core/link";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Box from "@material-ui/core/Box";
import { findMetadataValues } from "../../../utils";
import { DOCUMENT_TYPE_LABELS } from "../../../const";
import Paper from "@material-ui/core/Paper";
import PaperHeader from "../../paperheader";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MetadataID } from "../../../const";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		subTitle: {
			fontFamily: "monospace",
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "1.1rem",
			color: theme.palette.primary.main,
			marginLeft: theme.spacing(1)
		}
	}),
);

const ProductDocuments: React.FC<{ id: string }> = ({ id }) => {
	const classes = useStyles();
	const { generateLink } = useLinkHandler();
	const { data: documents } = useServiceFetch<C42MetadataResponse[]>({
		url: `/user/metadata/${MetadataID.Document}/content`,
		params: { filter: `products:${id}` }
	});

	if (!documents) {
		return null;
	}

	return (
		<Box mt={3}>
			<Paper>
				<List subheader={
					<ListSubheader>
						<PaperHeader>
							Dokumente
						</PaperHeader>
					</ListSubheader>
				}>
					{documents.map(document => (
						<ListItem
							button
							key={document.documentid}
							component={Link}
							to={generateLink({ toc: document.documentid, params: { product: id } })}
							dense
						>
							<ListItemText
								primary={(
									<span>
										{DOCUMENT_TYPE_LABELS[findMetadataValues(document.metadata, "type")[0]]}
										{findMetadataValues(document.metadata, "title")[0] && (
											<Typography
												variant="body2"
												color="textSecondary"
												component="span"
												className={classes.subTitle}
											>
												{findMetadataValues(document.metadata, "title")[0]}
											</Typography>
										)}
									</span>
								)}
							/>
						</ListItem>
					))}
				</List>
			</Paper>
		</Box>
	);
};

export default ProductDocuments;
