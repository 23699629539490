// Copyright (C) 2020 TANNER AG

import React from "react";
import OmniboxInput from "@c42-ui/core/omniboxinput";
import OmniboxAction from "@c42-ui/core/omniboxaction";
import { Option } from "@c42-ui/core/types";
import SearchIcon from "@material-ui/icons/Search";
import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import useTranslation from "@c42-ui/core/usetranslation";
import useSearchParams from "../usesearchparams";
import useAutoSuggest from "../useautosuggest";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		searchInput: {
			padding: "0 4px"
		},
		searchContainer: {
			backgroundColor: fade(theme.palette.text.primary, 0.03),
			boxShadow: "none",
			borderRadius: 2
		},
		focusedSearchContainer: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[1]
		}
	})
);

interface Props {
	global?: boolean;
}

const Omnibox: React.FC<Props> = ({ global }) => {
	const classes = useStyles();
	const { fetchAutoSuggest } = useAutoSuggest();
	const { t } = useTranslation();
	const { query } = useSearchParams();
	const { push, location } = useHistory();

	const onSuggestionSelected = (value: Option, event: React.FormEvent<any>) => {
		event.preventDefault();
		push(`/products/${value.id}`);
	};

	const onEnter = (query: string) => {
		if (global) {
			const lastPage = encodeURIComponent(location.pathname + location.search + location.hash);
			push(`/search?query=${query}&lastPage=${lastPage}`);
		} else {
			push(`/search?query=${query}`);
		}
	};

	return (
		<OmniboxInput
			placeholder={t("search.product.placeholder")}
			classes={{
				input: classes.searchInput,
				container: classes.searchContainer,
				containerFocused: classes.focusedSearchContainer
			}}
			fetchSuggestions={fetchAutoSuggest}
			alwaysRenderSuggestions={false}
			value={query}
			onEnter={onEnter}
			onSuggestionSelected={onSuggestionSelected}
			prefixActions={(
				<OmniboxAction title="Search">
					<SearchIcon/>
				</OmniboxAction>
			)}
		/>
	);
};

export default Omnibox;
