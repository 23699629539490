// Copyright (C) 2020 TANNER AG

import React from "react";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { C42MetadataResponse, C42TouchpointResponse } from "../../types";
import { findMetadataValues, findTouchpointValue } from "../../../utils";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import { TaskItem, TaskItemGroup } from "./task";
import Paper from "@material-ui/core/Paper";
import PaperHeader from "../../paperheader";
import { MetadataID } from "../../../const";

export interface Touchpoint {
	task: string;
	product: string;
	protocol: string;
	name: string;
	document: string;
	anchor: string;
}

export interface TouchpointGroup {
	name: string;
	items: Touchpoint[];
}

const transformsTouchpoints = (touchpoints: C42TouchpointResponse[], tasks: C42MetadataResponse[]): TouchpointGroup[] => {
	const groupedTouchpoints: TouchpointGroup[] = [];

	tasks.forEach(task => {
		const taskId = task.documentid;
		const touchpoint = touchpoints.find(touchpoint => findTouchpointValue(touchpoint.metadata, "task") === taskId);
		const group = findMetadataValues(task?.metadata || [], "group")[0];

		if (touchpoint) {
			const item: Touchpoint = {
				task: taskId,
				product: findTouchpointValue(touchpoint.metadata, "product"),
				protocol: findTouchpointValue(touchpoint.metadata, "protocol"),
				name: findMetadataValues(task?.metadata || [], "name")[0] || touchpoint.documentid,
				document: touchpoint.documentid,
				anchor: touchpoint.anchorid
			};

			if (!group) {
				groupedTouchpoints.push({
					name: "",
					items: [item]
				});
			} else {
				const touchpointGroup = groupedTouchpoints.find(groupedTouchpoint => groupedTouchpoint.name === group);
				if (touchpointGroup) {
					touchpointGroup.items.push(item);
				} else {
					groupedTouchpoints.push({
						name: group,
						items: [item]
					});
				}
			}
		}
	});

	return groupedTouchpoints;
};

const ProductTasks: React.FC<{ id: string }> = ({ id }) => {
	const { data: touchpoints } = useServiceFetch<C42TouchpointResponse[]>({
		url: `/user/touchpoints/document/content`,
		params: { filter: `product:${id}` }
	});
	const { data: tasks } = useServiceFetch<C42MetadataResponse[]>({
		url: `/user/metadata/${MetadataID.Task}/content`
	});

	if (!touchpoints || !tasks) {
		return null;
	}

	const groupedTouchpoints = transformsTouchpoints(touchpoints, tasks);

	return (
		<Paper>
			<List subheader={
				<ListSubheader>
					<PaperHeader>
						Aufgaben
					</PaperHeader>
				</ListSubheader>
			}>
				{groupedTouchpoints.map(group => {
					if (group.name) {
						return (
							<TaskItemGroup
								key={group.name}
								group={group}
							/>
						);
					}

					const touchpoint = group.items[0];
					return (
						<TaskItem
							key={touchpoint.name}
							touchpoint={touchpoint}
						/>
					);
				})}
			</List>
		</Paper>
	);
};

export default ProductTasks;
