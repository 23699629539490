// Copyright (C) 2019 TANNER AG

import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@c42-ui/core/container";
import SearchAppbar from "./appbar";
import SearchAI from "./ai";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import SearchFulltext from "./fulltext";
import useSearchParams from "../usesearchparams";
import { SearchMode } from "../../const";
import ShiftProvider from "@c42-ui/core/shiftprovider";
import useBreakpoint from "../usebreakpoint";
import SearchProvider from "./searchprovider";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center"
		},
		"@global": {
			"body": {
				backgroundColor: "#f7f7f7"
			}
		}
	}),
);

const Search: React.FC = () => {
	const classes = useStyles();
	const { search } = useLocation();
	const [lastPage, setLastPage] = useState("");
	const { searchMode } = useSearchParams();
	const { tabletUp } = useBreakpoint();

	useEffect(() => {
		const { lastPage } = parse(search);
		setLastPage(lastPage?.toString() || "/");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SearchProvider>
			<ShiftProvider
				shiftLeftWidth={tabletUp ? 300 : 0}
				shiftRightWidth={tabletUp ? 700 : 0}
			>
				<SearchAppbar lastPage={lastPage}/>
				<Container gutterTop className={classes.container}>
					{searchMode === SearchMode.Fulltext ? (
						<SearchFulltext/>
					) : (
						<SearchAI/>
					)}
				</Container>
			</ShiftProvider>
		</SearchProvider>
	);
};

export default Search;
