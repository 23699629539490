// Copyright (C) 2020 TANNER AG

import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			maxWidth: (props: { width: number | string }) => props.width,
			width: "100%"
		},
		paper: {
			width: "100%",
			maxWidth: (props: { width: number | string }) => props.width,
			boxShadow: theme.shadows[1]
		},
		placeholder: theme.mixins.toolbar
	})
);

interface SideDrawerProps {
	open?: boolean;
	setOpen?(open: boolean): void;
	width?: number | string;
	appBarDisabled?: boolean,
	anchor?: "right" | "left",
	className?: string;
	variant?: "permanent" | "persistent" | "temporary"
}

const SideDrawer: React.FC<SideDrawerProps> = (props) => {
	const { open, setOpen, children, appBarDisabled, className, width = 450, anchor = "right", variant = "persistent" } = props;
	const classes = useStyles({ width });

	return (
		<Drawer
			variant={variant}
			anchor={anchor}
			open={open}
			onClose={() => setOpen && setOpen(false)}
			classes={{
				root: classes.root,
				paper: clsx(classes.paper, className),
			}}
		>
			{!appBarDisabled && (
				<div className={classes.placeholder}/>
			)}
			{children}
		</Drawer>
	);
};

export default SideDrawer;
