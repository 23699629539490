// Copyright (C) 2020 TANNER AG

import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useBreakpoint from "../../usebreakpoint";
import FilterChip from "./filterchip";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: 0,
			marginBottom: 2,
			marginRight: 0,
			marginLeft: 0
		},
		count: {
			marginLeft: theme.spacing(0.5),
			fontSize: "0.875rem"
		},
		checkbox: {
			padding: 0
		},
		label: {
			fontSize: "0.875rem",
			marginLeft: theme.spacing(0.5)
		}
	})
);

interface Props {
	label: string;
	value: string;
	checked?: boolean;
	count?: number;
	onChange(value: string, checked: boolean): void;
}

const FilterCheckbox: React.FC<Props> = ({ label, value, count, checked, onChange }) => {
	const classes = useStyles();
	const { tabletDown } = useBreakpoint();

	if (tabletDown) {
		return (
			<FilterChip
				label={label}
				value={value}
				checked={checked}
				onChange={onChange}
			/>
		);
	}

	return (
		<FormControlLabel
			className={classes.root}
			control={(
				<Checkbox
					value={value}
					disableRipple
					color="primary"
					size="small"
					onChange={(unknown, checked) => onChange(value, checked)}
					checked={checked}
					className={classes.checkbox}
				/>
			)}
			label={(
				<span className={classes.label}>
					{label}
					{count !== undefined && (
						<Typography
							color="textSecondary"
							component="span"
							className={classes.count}
						>
							({count})
						</Typography>
					)}
				</span>
			)}
		/>
	);
};

export default FilterCheckbox;
