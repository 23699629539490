// Copyright (C) 2020 TANNER AG

import { TocItem } from "@c42-ui/core/types";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { useHistory } from "react-router-dom";
import { useHashLink } from "@c42-ui/core/utils/usehashscroll";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { buildTocEntry } from "./builder";
import $ from "jquery";
import { TocScroller } from "./tocscroller";
import useTocLayout from "./layout";

const flat = (tocItems: TocItem[], flattedItems: ScrollItem[], parents: string[]) => {
	tocItems.forEach(item => {
		const activator = item.target.anchor || "";
		const elements = parents.slice();
		elements.push(activator);

		flattedItems.push({
			activator,
			elements
		});
		flat(item.nodes, flattedItems, elements);
	});
};

export interface ScrollItem {
	activator: string;
	elements: string[];
}

interface Options {
	id: string;
	items: TocItem[];
	offset?: number;
	update?: boolean;
	scrollToc?: boolean;
	onActivate?(item: ScrollItem): void;
	onTargetClick?(): void;
}

const useTocBuilder = ({ id, items, offset, onActivate, onTargetClick, scrollToc, update }: Options) => {
	const { generateLink } = useLinkHandler();
	const { push } = useHistory();
	const executeHashScroll = useHashLink();
	const [tocScroller, setTocScroller] = useState<TocScroller>();

	const rootSelector = `#${id}`;

	useTocLayout();

	const scrollItems = useMemo(() => {
		const scrollItems: ScrollItem[] = [];
		flat(items, scrollItems, []);

		return scrollItems.filter(item => item);
	}, [items]);

	useLayoutEffect(() => {
		const root = document.getElementById(id);

		if (!root) {
			return;
		}

		root.innerHTML = items.map(item => buildTocEntry(item)).join("");

		// After update toc in DOM - force toc position for reselection active elements
		tocScroller?.forceProcess();

		$(`${rootSelector} .nav-link`).on("click",
			(event) => {
				const dataSet = event.currentTarget.dataset;

				push(generateLink({
					toc: dataSet.toc ?? "",
					content: dataSet.content ?? "",
					anchor: dataSet.target?.substring(1) ?? ""
				}));

				onTargetClick && onTargetClick();

				executeHashScroll(dataSet.target?.substring(1));

				event.preventDefault();
			});

		$(`${rootSelector} .tocListItemAction`).on("click",
			(event) => {
				const dataSet = event.currentTarget.dataset;
				$(`${rootSelector} [data-target="${dataSet.target}"]`).toggleClass("open");

				event.preventDefault();
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useLayoutEffect(() => {
		if (items.length > 0 && !tocScroller) {
			// @ts-ignore
			setTocScroller(new TocScroller(window,
				{
					offset,
					items: scrollItems,
					onActivate,
					scrollToc,
					rootId: id
				}
			));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		if (items.length && tocScroller && update) {
			tocScroller.update(scrollItems);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	return {
		tocScroller
	};
};

export default useTocBuilder;
