// Copyright (C) 2020 TANNER AG

import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useDocumentToc from "../usedocumenttoc";
import useDocument from "../provider";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Link from "@c42-ui/core/link";
import useBreakpoint from "../../usebreakpoint";

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			position: "absolute",
			top: "100%",
			right: 0,
			marginTop: theme.spacing(0.5),
			padding: theme.spacing(0, 1),

			[theme.breakpoints.up("md")]: {
				padding: theme.spacing(0, 3)
			}
		},
		startIcon: {
			marginRight: 2
		},
		endIcon: {
			marginLeft: 2
		},
		btnPrev: {
			backgroundColor: theme.palette.common.white,

			[theme.breakpoints.up("md")]: {
				minWidth: 110
			}
		},
		btnNext: {
			[theme.breakpoints.up("md")]: {
				minWidth: 110
			},
			marginLeft: theme.spacing(1)
		}
	})
);

const DocumentControls: React.FC = () => {
	const classes = useStyles();
	const { tocItems } = useDocument();
	const { spUp } = useBreakpoint();
	const { previous, next } = useDocumentToc(tocItems || [])
	const { generateLink } = useLinkHandler();

	return (
		<div className={classes.root}>
			<Button
				variant="outlined"
				size="small"
				color="primary"
				startIcon={<NavigateBeforeIcon/>}
				className={classes.btnPrev}
				classes={{ startIcon: classes.startIcon }}
				component={Link}
				disabled={!Boolean(previous)}
				to={(previous) ? generateLink(previous.target) : "/"}
			>
				{spUp && "Previous"}
			</Button>
			<Button
				variant="contained"
				size="small"
				color="primary"
				endIcon={<NavigateNextIcon/>}
				className={classes.btnNext}
				classes={{ endIcon: classes.endIcon }}
				component={Link}
				disabled={!Boolean(next)}
				to={(next) ? generateLink(next.target) : "/"}
			>
				{spUp && "Next"}
			</Button>
		</div>
	);
};

export default DocumentControls;
