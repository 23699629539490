// Copyright (C) 2020 TANNER AG

import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import PaperHeader from "../../paperheader";
import List from "@material-ui/core/List";
import { SearchItem } from "./searchai";
import { TaskInfo } from "../../types";

interface Props {
	taskOptions: { id: string; name: string }[];
	tasks: TaskInfo[];
	selected?: string;
	intent?: string;
	onClick(id?: string): void;
}

const SearchAITasks: React.FC<Props> = ({ taskOptions, tasks, selected, onClick }) => {
	return (
		<List
			disablePadding
			dense
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					<PaperHeader>Wählen Sie ihre Aufgabe</PaperHeader>
				</ListSubheader>
			}
		>
			{taskOptions.map(task => (
				<SearchItem
					key={task.id}
					selected={selected === task.id}
					onClick={() => onClick(task.id)}
					label={tasks.find(taskInfo => taskInfo.id === task.id)?.name || task.id}
				/>
			))}
		</List>
	);
};

export default SearchAITasks;
