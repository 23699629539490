// Copyright (C) 2019 TANNER AG

import * as React from "react";
import Drawer from "@material-ui/core/Drawer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import useC42 from "@c42-ui/core/usec42";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: "transparent",
			flexDirection: "row",
			boxShadow: "none",
			overflowY: "visible",
			maxWidth: (props: { width: number }) => props.width,
			width: "100%"
		},
		paper: {
			width: "100%",
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[16]
		},
		closeButton: {
			marginRight: theme.spacing(2),
		},
		logo: {
			maxHeight: 30,
			marginLeft: "auto"
		}
	})
);

interface Props {
	open: boolean;
	onClose(): void;
	width?: number;
}

const NavigationDrawer: React.FC<Props> = ({ open, onClose, children, width = 300 }) => {
	const { basename } = useLinkHandler();
	const { setup: { app: { name } } } = useC42();
	const classes = useStyles({ width });
	return (
		<Drawer
			open={open}
			onClose={onClose}
			classes={{ paper: classes.root }}
		>
			<Box className={classes.paper}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.closeButton}
						color="inherit"
						aria-label="close navigation"
						onClick={onClose}
					>
						<CloseIcon/>
					</IconButton>
					<img
						src={`${basename}/logo.svg`}
						alt={name}
						className={classes.logo}
					/>
				</Toolbar>
				{children}
			</Box>
		</Drawer>
	);
};

export default NavigationDrawer;
