// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { scrollbarSmall } from "@c42-ui/core/utils/mixins";
import { Drawer } from "@material-ui/core";
import useShift from "@c42-ui/core/useshift";
import List from "@material-ui/core/List";
import DocumentTocToolbar from "../toolbar";
import DocumentToc from "../documenttoc";
import { TocItem } from "@c42-ui/core/types";
import useBreakpoint from "../../../usebreakpoint";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			width: (props: { width: number | string }) => props.width,
			padding: "125px 0 0 16px",
			backgroundColor: "transparent",
			overflowY: "visible",
			borderRight: "none"
		},
		paperInner: {
			overflow: "hidden",
			maxWidth: "100%",
			display: "flex",
			flexDirection: "column"
		},
		paperScroll: {
			overflow: "auto",
			...scrollbarSmall
		},
		header: {
			color: theme.palette.grey.A200,
			justifyContent: "space-between"
		},
		list: {
			width: "100%",
			maxWidth: (props: { width: number | string }) => props.width,
			backgroundColor: theme.palette.background.paper,
			paddingTop: 0,
			paddingBottom: 0
		}
	}),
);

interface Props {
	tocItems: TocItem[];
}

const DocumentTocDrawer: React.FC<Props> = ({ tocItems }) => {
	const { shiftLeft: { width } } = useShift();
	const classes = useStyles({ width });
	const [query, setQuery] = useState("");
	const { tabletDown } = useBreakpoint();

	if (tabletDown) {
		return null;
	}

	return (
		<Drawer
			variant="persistent"
			anchor="left"
			open
			classes={{
				paper: classes.paper,
			}}
		>
			<div className={classes.paperInner}>
				<DocumentTocToolbar
					query={query}
					setQuery={setQuery}
				/>
				<div
					id="tocScrollRoot"
					className={classes.paperScroll}
				>
					<List
						dense
						component="nav"
						className={classes.list}
					>
						<DocumentToc
							id="desktopToc"
							tocItems={tocItems}
							query={query}
						/>
					</List>
				</div>
			</div>
		</Drawer>
	);
};

export default DocumentTocDrawer;
