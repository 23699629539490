// Copyright (C) 2020 TANNER AG

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const useBreakpoint = () => {
	const { breakpoints } = useTheme();
	// Tablet
	const tabletUp = useMediaQuery(breakpoints.up("lg"));
	const tabletDown = useMediaQuery(breakpoints.down("md"));
	// Smartphone
	const spUp = useMediaQuery(breakpoints.up("md"));
	const spDown = useMediaQuery(breakpoints.down("sm"));

	return {
		tabletUp,
		tabletDown,
		spUp,
		spDown
	};
};

export default useBreakpoint;
