// Copyright (C) 2020 TANNER AG

import React from "react";
import { TocItem } from "@c42-ui/core/types";
import Container from "@c42-ui/core/container";
import useDocumentToc from "../usedocumenttoc";
import ChapterLoader from "./chapterloader";
import useDesign from "./design";
import useHashScroll from "@c42-ui/core/utils/usehashscroll";

interface Props {
	documentId: string;
	tocItems: TocItem[];
}

const DocumentLoader: React.FC<Props> = React.memo(({ documentId, tocItems }) => {
	const { current } = useDocumentToc(tocItems);

	useDesign();
	useHashScroll();

	return (
		<Container>
			<ChapterLoader documentId={documentId} section={current}/>
		</Container>
	);
});

export default DocumentLoader;
