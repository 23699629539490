// Copyright (C) 2020 TANNER AG

import React from "react";
import Box from "@material-ui/core/Box";
import { C42TouchpointResponse, DocumentInfo } from "../../types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import useDocuments from "../../usedocuments";
import { textFontFamily } from "../../../theme";
import { DOCUMENT_TYPE_LABELS } from "../../../const";
import { useHistory } from "react-router-dom";
import { parse } from "query-string";
import useTasks from "../../usetasks";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { LinkTarget } from "@c42-ui/core/types";
import { useHashLink } from "@c42-ui/core/utils/usehashscroll";

const useStyles = makeStyles(theme =>
	createStyles({
		card: {
			marginBottom: theme.spacing(2),
			boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
			borderRadius: 2
		},
		content: {
			padding: theme.spacing(1, 2)
		},
		title: {
			fontSize: "1.25rem",
			color: theme.palette.primary.main,
			fontFamily: textFontFamily
		},
		subTitle: {
			fontSize: "1rem",
			fontFamily: textFontFamily,
			fontWeight: theme.typography.fontWeightBold
		}
	})
);

interface Props {
	label: string;
	document?: DocumentInfo;
	documentid: string;
	anchorid: string;
	title: string;
	preview: string;
}

const SubTouchpoint: React.FC<Props> = ({ document, documentid, anchorid, title, preview }) => {
	const classes = useStyles();
	const { location: { search }, push } = useHistory();
	const { task, product } = parse(search);
	const executeHashScroll = useHashLink();

	const { generateLink } = useLinkHandler();
	const target: LinkTarget = {
		toc: documentid,
		anchor: anchorid,
		// @ts-ignore
		params: { task, product }
	};

	const execute = () => {
		push(generateLink(target));
		executeHashScroll(anchorid);
	};

	return (
		<Card className={classes.card}>
			<CardActionArea onClick={execute}>
				<CardContent className={classes.content}>
					<Typography
						className={classes.title}
						variant="h4"
						component="h3"
						gutterBottom
					>
						{document?.name} - {DOCUMENT_TYPE_LABELS[document?.type || ""]}
					</Typography>
					<Typography gutterBottom className={classes.subTitle} variant="h4" component="h4">{title}</Typography>
					<Typography variant="body2">{preview}...</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

const DocumentTouchpointsDrawer: React.FC<{ touchpoint: C42TouchpointResponse }> = ({ touchpoint }) => {
	const { documents } = useDocuments();
	const { tasks } = useTasks();
	const { location: { search } } = useHistory();
	const { task: taskId, protocol } = parse(search);

	const task = tasks.find(task => task.id === taskId);

	return (
		<Box mt={2}>
			<Box mb={2} display="flex">
				<Box width="50%">
					<Typography variant="caption">Aufgabe</Typography>
					<Typography variant="subtitle2">{task?.name || "-"}</Typography>
				</Box>
				{protocol && (
					<Box width="50%">
						<Typography variant="caption">Protokoll</Typography>
						<Typography variant="subtitle2">{protocol}</Typography>
					</Box>
				)}
			</Box>
			{touchpoint.subtouchpoints.map((subtouchpoint, index) => (
				<SubTouchpoint
					key={index}
					label={subtouchpoint.documentid}
					document={documents.find(document => document.id === subtouchpoint.documentid)}
					{...subtouchpoint}
				/>
			))}
		</Box>
	);
};

export default DocumentTouchpointsDrawer;
