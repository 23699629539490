// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";
import NavigationDrawer from "../navigationdrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Link from "@c42-ui/core/link";
import useProducts from "../useproducts";
import { ProductInfo } from "../types";
import { MEDIA_SERVER_URL } from "../../const";
import { TabIndex } from "./index";
import NavigationTabs from "./tabs";
import NavigationDocumentToc from "./documenttoc";

const NavigationItem: React.FC<{ id: string, closeDrawer(): void, product: ProductInfo }> = ({ id, closeDrawer, product }) => {
	if (!product) {
		return null;
	}

	return (
		<ListItem button component={Link} to={`/products/${id}`} onClick={closeDrawer}>
			<ListItemAvatar>
				<Avatar
					src={`${MEDIA_SERVER_URL}/${id}_thumbnail.png`}
					alt={product.name}
				/>
			</ListItemAvatar>
			<ListItemText primary={product.name} secondary={product.description}/>
		</ListItem>
	);
};

const Navigation: React.FC<{ open: boolean, setOpen: (open: boolean) => void }> = ({ open, setOpen }) => {
	const { products } = useProducts();
	const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.Product);

	const onTabChange = (index: TabIndex) => setTabIndex(index);
	const onClose = () => setOpen(false);

	return (
		<NavigationDrawer open={open} onClose={onClose} width={380}>
			<NavigationTabs
				index={tabIndex}
				setIndex={onTabChange}
			/>
			{tabIndex === TabIndex.Product && (
				<List>
					{products.map(product => (
						<NavigationItem
							key={product.id}
							closeDrawer={() => setOpen(false)}
							id={product.id}
							product={product}
						/>
					))}
				</List>
			)}
			{tabIndex === TabIndex.Document && (
				<NavigationDocumentToc onTargetClick={onClose}/>
			)}
		</NavigationDrawer>
	);
};

export default Navigation;
