// Copyright (C) 2020 TANNER AG

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const SearchProgress = () => (
	<Box display="flex" alignItems="center" justifyContent="center" py={6}>
		<CircularProgress/>
	</Box>
);

export default SearchProgress;
