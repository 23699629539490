// Copyright (C) 2020 TANNER AG

import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import PaperHeader from "../../paperheader";
import List from "@material-ui/core/List";
import { SearchItem } from "./searchai";

interface Props {
	protocols: string[];
	selected?: string;
	intent?: string;
	onClick(id?: string): void;
}

const SearchAIProtocols: React.FC<Props> = ({ protocols, selected, onClick }) => {
	return (
		<List
			disablePadding
			dense
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					<PaperHeader>Wählen Sie ihr Protokoll</PaperHeader>
				</ListSubheader>
			}
		>
			{protocols.map(protocol => (
				<SearchItem
					key={protocol}
					label={protocol}
					selected={selected === protocol}
					onClick={() => onClick(protocol)}
				/>
			))}
		</List>
	);
};

export default SearchAIProtocols;
