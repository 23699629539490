// Copyright (C) 2020 TANNER AG

import React from "react";
import { useImage } from "../imageprovider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
	src: string;
	alt: string;
	imageProps: { [p: string]: string | undefined }
}

const useStyles = makeStyles(theme =>
	createStyles({
		image: {
			transition: theme.transitions.create("box-shadow"),

			"&:hover": {
				cursor: "pointer",
				boxShadow: theme.shadows[1]
			}
		}
	})
);

const ContentImage: React.FC<Props> = ({ src, alt, imageProps }) => {
	const classes = useStyles();
	const { setSource } = useImage();
	const { class: additionalClass, ...attr } = imageProps;

	return (
		<img
			{...attr}
			onClick={() => setSource(src)}
			src={src}
			alt={alt}
			className={clsx(classes.image, additionalClass)}
		/>
	);
};

export default ContentImage;
