// Copyright (C) 2019 TANNER AG

import React from "react";
import { C42Setup, createC42Setup } from "@c42-ui/core/c42setup";
import { theme as lightTheme, darkTheme } from "./theme";
import C42Provider from "@c42-ui/core/c42provider";
import { BrowserRouter as Router } from "react-router-dom";
import TranslationProvider from "@c42-ui/core/translationprovider";
import { Language } from "@c42-ui/core/consts";
import { HtmlParser, LinkGenerator, LinkTarget } from "@c42-ui/core/types";
import { DataAttribute } from "@c42-ui/core/consts";
import { LinkHandlerContext } from "@c42-ui/core/usec42/c42context";
import { DomElement, domToReact } from "html-react-parser"
import { isTargetLink } from "@c42-ui/core/htmlparser/htmlparser";
import Link from "@c42-ui/core/link";
import { stringify } from "query-string";
import ContentImage from "./components/contentimage";

// Custom link path generator by link target
const linkGenerator: LinkGenerator = (target: LinkTarget, setup: C42Setup): string => {
	if (target.type === "product") {
		return setup.routes.product.replace(":id", encodeURIComponent(target.content || ""))
	}

	let link = setup.routes.document.replace(":id", encodeURIComponent(target.toc || ""));
	let params: { [key: string]: string | number } = { ...target.params };

	if (target.content) {
		params.fragment = target.content;
	}

	if (Object.entries(params).length) {
		link += `?${stringify(params)}`;
	}

	if (target.anchor) {
		link += `#${target.anchor}`;
	}

	return link;
};

// Custom html parser
const htmlParser: HtmlParser = (element: DomElement, context: LinkHandlerContext) => {
	if (element.name === "a" && element.attribs && isTargetLink(element.attribs)) {
		const target: LinkTarget = {
			toc: element.attribs[DataAttribute.toc],
			content: element.attribs[DataAttribute.content],
			anchor: element.attribs[DataAttribute.anchor],
		};

		return (
			<Link to={context.generateLink(target)}>
				{element.children && domToReact(element.children)}
			</Link>
		);
	}

	if (element.name === "img" && element.attribs && element.attribs.hasOwnProperty("src")) {
		return (
			<ContentImage
				imageProps={element.attribs}
				src={element.attribs.src}
				alt={element.attribs.alt}
			/>
		);
	}

	return false;
};

const setup = createC42Setup({
	services: {
		domain: process.env.REACT_APP_SERVICES_DOMAIN,
		prefix: process.env.REACT_APP_SERVCIES_PREFIX
	},
	app: {
		name: process.env.REACT_APP_NAME,
		linkGenerator,
		htmlParser,
		offset: 106
	},
	auth: true,
	theme: {
		lightTheme,
		darkTheme
	}
});

const Setup: React.FC = (props) => (
	<Router basename={setup.app.basename}>
		<C42Provider setup={setup}>
			<TranslationProvider
				defaultLanguage={Language.DE}
				languages={[Language.DE]}
			>
				{props.children}
			</TranslationProvider>
		</C42Provider>
	</Router>
);

export default Setup;
