// Copyright (C) 2020 TANNER AG

import React from "react";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import { scrollbarSmall } from "@c42-ui/core/utils/mixins";
import clsx from "clsx";

interface Props {
	label: string;
	row?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		innerRoot: {
			padding: theme.spacing(1, 2),
			backgroundColor: theme.palette.background.paper,
			height: "100%"
		},
		label: {
			flexGrow: 1,
			fontSize: "1rem"
		},
		expand: {
			transform: "rotate(0deg)",
			marginLeft: "auto",
			transition: theme.transitions.create("transform", {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: "rotate(180deg)",
		},
		collapse: {
			overflow: "auto",
			maxHeight: "calc(100% - 30px)",
			...scrollbarSmall
		},
		groupRow: {
			flexDirection: "row"
		}
	})
);

const FilterGroup: React.FC<Props> = ({ label, children, row }) => {
	const classes = useStyles();

	return (
		<Box>
			<Box className={classes.innerRoot}>
				<Box display="flex">
					<Typography className={classes.label} variant="h6">{label}</Typography>
				</Box>
				<Collapse className={classes.collapse} in timeout="auto" unmountOnExit>
					<FormGroup className={clsx({ [classes.groupRow]: row })}>
						{children}
					</FormGroup>
				</Collapse>
			</Box>
		</Box>
	);
};

export default FilterGroup;
