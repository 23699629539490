// Copyright (C) 2020 TANNER AG

import { HttpHeader, HttpMethod } from "@c42-ui/core/consts";
import { SearchResponse, FULLTEXT_ITEM_SIZE } from "./types";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import useFetch from "../../usefetch";
import useAuth from "@c42-ui/core/useauth";
import useSearchParams from "../../usesearchparams";
import { DatabaseID } from "../../../const";

const useFulltextSearch = (query: string, page = 1) => {
	const { service: { baseUrl } } = useLinkHandler();
	const { getBearer } = useAuth();
	const { filter } = useSearchParams();

	const { data, loading } = useFetch<SearchResponse>({
		url: `${baseUrl}/user/search`,
		method: HttpMethod.POST,
		headers: {
			"Content-Type": "application/json",
			[HttpHeader.Authorization]: getBearer()
		},
		isLazy: !Boolean(query),
		body: JSON.stringify({
			databaseId: DatabaseID.Content,
			query,
			limit: FULLTEXT_ITEM_SIZE,
			start: (page - 1) * FULLTEXT_ITEM_SIZE,
			filter
		})
	});

	return {
		hits: data?.hits,
		loading
	};
};

export default useFulltextSearch;
