// Copyright (C) 2019 TANNER AG

import { useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { TocItem, LinkTarget } from "@c42-ui/core/types";
import { parse } from "query-string";

interface DocumentToc {
	current: TocItem;
	previous?: TocItem | false;
	next?: TocItem | false,
	items: TocItem[];
}

const useDocumentToc = (tocItems: TocItem[]): DocumentToc => {
	const { hash, search } = useLocation();
	const items: TocItem[] = JSON.parse(JSON.stringify(tocItems));

	const findChapterByAnchor = useCallback((items: TocItem[], attr: keyof LinkTarget, value: string): number => (
		items.findIndex((item: TocItem) => {
			if (item.target[attr] === value) {
				return true;
			}

			return findChapterByAnchor(item.nodes, attr, value) !== -1;
		})
	), []);

	return useMemo(() => {
		const queries = parse(search);
		const anchor = hash.substr(1);
		const parsedItems: TocItem[] = items;

		let index: number;

		// Search at first for content/fragment mapping - otherwise with anchor
		if (queries && queries.fragment) {
			index = findChapterByAnchor(parsedItems, "content", queries.fragment.toString());
		} else {
			index = findChapterByAnchor(parsedItems, "anchor", anchor);
		}

		if (index === -1) {
			index = 0;
		}

		parsedItems[index] = {
			...parsedItems[index],
			open: true
		};

		return {
			previous: (index > 0) && parsedItems[index - 1],
			current: parsedItems[index],
			next: (index + 1 < parsedItems.length) && parsedItems[index + 1],
			items: parsedItems
		};
	}, [hash, search, items, findChapterByAnchor]);
};

export default useDocumentToc;
